@charset "UTF-8";
/**
 * 0.1 Colors
 * -----------------------------------------------------------------------------
 */
html {
  overflow: scroll;
  overflow-x: hidden;
}

.clearfix:after,
.timeline .time-bar-element:after,
.eventlist .event:after,
.search-results article:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  line-height: 1px;
}

#PageFooter {
  overflow: auto;
}

img {
  max-width: 100%;
  height: auto;
}

.screen-reader-text {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* Pager
================================================== */
.pagination {
  text-align: center;
}

.pagination .page-numbers {
  display: inline-block;
  text-decoration: none;
  height: 50px;
  line-height: 50px;
  width: 50px;
  text-align: center;
}

.pagination .page-numbers:hover {
  text-decoration: none;
}

.pagination .page-numbers.current {
  background: #000000;
  color: #fff;
}

.pagination .page-numbers.next, .pagination .page-numbers.prev {
  width: 125px;
}

.pagination .page-numbers.next {
  text-align: left;
}

.pagination .page-numbers.prev {
  text-align: right;
}

/**
 * 4 SLICK SLIDER
 * -----------------------------------------------------------------------------
 */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  border: 0;
  font-size: 0;
  line-height: 0;
  color: transparent;
  cursor: pointer;
}

.slick-next {
  right: 0;
  left: auto;
}

.slick-disabled {
  opacity: 0.15;
}

.slick-dots {
  margin: 20px 0 0 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  display: inline-block;
  margin: 0 2px;
  opacity: 0.33;
}

.slick-dots .slick-active {
  opacity: 1;
}

.slick-dots button {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  border: 0;
  font-size: 0;
  line-height: 0;
  color: transparent;
  background: transparent;
  cursor: pointer;
}

.slick-dots button:focus {
  outline: none;
}

.slick-dots button:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #76222F;
  transform: translate(-50%, -50%);
}

button.slick-arrow:before {
  display: none;
}

button.slick-arrow {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  z-index: 2;
}

button.slick-next:before, button.slick-prev:before {
  display: block;
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  font-size: 2rem;
}

/* SLICK */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  position: relative;
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Global :: Elements  */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font,
img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i,
center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  overflow-y: scroll;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

/*blockquote, q { quotes: none; }*/
/*blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }*/
:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* HTML5 :: Elements */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

/* Clear floating elements */
.screen-clear,
.tablet-clear,
.mobile-clear {
  display: none;
  visibility: hidden;
  clear: both;
  height: 0;
  float: none;
}

.clearfix {
  display: block;
}

.clearfix:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  line-height: 1px;
}

/* Special for mobile devices */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
Theme Name: Fürstenberg Tourismus
Theme URI: http://www.dotfly.de
Author: Dustin Breuer <dustin.breuer@dotfly.de>
Author URI: http://www.dotfly.de
Template: fuerstenberg-tourismus
Description: Das Fürstenberg-Tourismus Template
Version: 1
License: Commercial
License URI: http://www.dotfly.de
Tags:
Text Domain: fbt
 */
/* line 5, ../../../../../../../../../lib/gems/1.9.1/gems/compass-core-1.1.0.alpha.3/stylesheets/compass/reset/_utilities.scss */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

h1, h2, h3, h4, h5, h6,
.heading-1, .heading-2, .heading-3, time,
.time-like, .section-headline, .text-columns .headline, .sidebar-header, .slogan-quotation, .timeline .year {
  font-family: 'Sabon LT W01 Roman', serif;
}

.slogan, .slogan-quotation blockquote p, .single-post blockquote p {
  font-family: 'Sabon LT W01 Italic', serif;
}

.news .news-headline, .single-post .entry-header .entry-meta,
.single-post .entry-header .entry-date {
  font-family: 'HelveticaNeueW01-47LtCn_692686', sans-serif;
}

html, body, .subline-1,
.subline-2,
.subline-3,
.subline-4, .caption, .gallery .rsDefault .rsGCaption, .single-post .wp-caption-text, p, .button, .button-primary, .em-booking-form .em-booking-form-details .em-booking-submit {
  font-family: 'HelveticaNeueW01-45Ligh', sans-serif;
}

strong {
  font-family: 'HelveticaNeueW01-65Medi', sans-serif;
}

a {
  font-family: 'HelveticaNeueW01-ThinCn_673383', sans-serif;
}

html, body {
  color: #000000;
  font-size: 18px;
  line-height: 28px;
}

h1, h2, h3, h4, h5, h6,
.heading-1, .heading-2, .heading-3 {
  color: #000000;
  line-height: 1.0;
  letter-spacing: 0;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
.heading-1 a, .heading-2 a, .heading-3 a {
  text-decoration: none;
  color: #000000;
}

h1,
.heading-1 {
  font-size: 48px;
}

h2,
.heading-2 {
  font-size: 36px;
  margin-bottom: 30px;
}

h3,
.heading-3 {
  font-size: 32px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 28px;
}

h6 {
  font-size: 22px;
}

.subline-1,
.subline-2,
.subline-3,
.subline-4 {
  color: #000000;
}

.subline-1 {
  font-size: 26px;
  line-height: 32px;
}

.subline-2 {
  font-size: 20px;
  line-height: 26px;
}

.subline-3 {
  font-size: 18px;
  line-height: 24px;
}

.subline-4 {
  font-size: 16px;
  line-height: 22px;
}

.caption, .gallery .rsDefault .rsGCaption, .single-post .wp-caption-text {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.8px;
  color: #000000;
}

a {
  color: #000000;
  text-decoration: underline;
}

a:hover, #Navigation .menu-item.current-menu-parent > a, #Navigation .menu-item.current-menu-item > a {
  color: #ADAFB2;
}

a:active {
  color: #DDDDDD;
}

a.small {
  font-size: 15px;
}

p {
  margin-top: 20px;
}

p.small {
  font-size: 15px;
  line-height: 24px;
}

time,
.time-like {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0;
}

time.medium,
.time-like.medium {
  font-size: 21px;
  line-height: 26px;
}

time.small,
.time-like.small {
  font-size: 18px;
  line-height: 23px;
}

.section-headline, .text-columns .headline, .sidebar-header {
  font-size: 19px;
  letter-spacing: 7px;
  line-height: 40px;
  text-transform: uppercase;
}

.section-headline.small, .text-columns .small.headline, .small.sidebar-header {
  font-size: 16px;
  line-height: 30px;
}

hr {
  border: none;
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 50px;
}

hr.signet {
  height: 30px;
  border-bottom: none;
  background: url("./img/bg_signet.png") no-repeat center;
}

@media only screen and (max-width: 640px) {
  hr.signet {
    height: 24px;
    background: url("./img/bg_signet_small.png") no-repeat center;
  }
}

.slogan {
  font-size: 34px;
  line-height: 41px;
}

.slogan.small {
  opacity: 0.89;
  font-size: 24px;
  line-height: 28px;
}

.button, .button-primary, .em-booking-form .em-booking-form-details .em-booking-submit {
  width: 207px;
  height: 50px;
  line-height: 50px;
  text-decoration: none;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 1.35px;
  text-align: center;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}

.button-primary, .em-booking-form .em-booking-form-details .em-booking-submit {
  background-color: #000000;
}

.button-primary:hover, .em-booking-form .em-booking-form-details .em-booking-submit:hover {
  background-color: #ADAFB2;
}

.button-primary:active, .em-booking-form .em-booking-form-details .em-booking-submit:active {
  background-color: #DDDDDD;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

#Primary {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 40.1rem) {
  #Primary {
    padding-left: 3%;
    padding-right: 3%;
  }
}

/* Global Grid Column Setup
=================================================== */
.col {
  float: left;
  padding-left: 1%;
  padding-right: 1%;
  margin-bottom: 18px;
}

.col-12 .col-12 {
  padding: 0;
}

.container {
  max-width: 1440px;
  padding: 0 0 0;
  margin: 0 auto;
}

/* Global Grid Columns
=================================================== */
.col-12 {
  width: 100%;
}

.col-11 {
  width: 91.66667%;
}

.col-10 {
  width: 83.33333%;
}

.col-9 {
  width: 75%;
}

.col-8 {
  width: 66.66667%;
}

.col-7 {
  width: 58.33333%;
}

.col-6 {
  width: 50%;
}

.col-5 {
  width: 41.66667%;
}

.col-4 {
  width: 33.33333%;
}

.col-3 {
  width: 25%;
}

.col-2 {
  width: 16.66667%;
}

.col-1 {
  width: 8.33333%;
}

.col-centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.col-fullscreen {
  float: none;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

/* Global Grid Tablet
=================================================== */
@media only screen and (max-width: 960px) {
  /* Tablet Column Setup
    =================================================== */
  .col {
    padding-left: 1.5%;
    padding-right: 1.5%;
  }
  .col-8.col-centered,
  .col-12.container {
    padding-left: 2%;
    padding-right: 2%;
  }
}

/**



    .gallery .col.col-centered {
         padding-left: 0;
         padding-right: 0;
     }

    .calendar .col-8.col-centered {
        padding: 28px 20px;
    }

    .posts .col {
        float: none;
        width: 100%;
    }

    .posts .col-4 {
        width: 83.333%;
        margin-left: auto;
        margin-right: auto;
    }

    .posts .col-8 {
        margin-top: $margin-medium;
    }

*/
/* Global Grid Smartphone
=================================================== */
@media only screen and (max-width: 640px) {
  /* Smartphone Column Setup
    =================================================== */
  .col {
    float: none;
    padding: 0;
  }
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11 {
    width: 100%;
  }
  .col-8.col-centered,
  .col-12.container {
    padding-left: 4%;
    padding-right: 4%;
  }
}

/**
@media only screen and (max-width: 640px) {

    .content-module-block .col-1,
    .content-module-block .col-2,
    .content-module-block .col-3,
    .content-module-block .col-4,
    .content-module-block .col-5,
    .content-module-block .col-6,
    .content-module-block .col-7,
    .content-module-block .col-8,
    .content-module-block .col-9,
    .content-module-block .col-10,
    .content-module-block .col-11,
    .content-module-block .col-12,
    .content-module-block .col-8.col-centered {
        float: none;
        width: 100%;
    }

    .content-module-block .col-8.col-centered {
        padding-left: 4%;
        padding-right: 4%;
    }

    .content-module-block.text .col-8.col-centered,
    .content-module-block.image-text-centered .col-8.col-centered {
        padding: 0;
    }

    .content-module-block .col .col {
        padding-left: 0;
        padding-right: 0;
    }
*/
/* Global Grid IE Lower 9 Fixing
=================================================== */
.lt-ie9 .container {
  min-width: 768px;
}

.lt-ie8 .container {
  min-width: inherit;
  width: 960px;
  padding: 10px 10px 0;
}

.lt-ie8 .col {
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}

/* v1.0.5 */
/* Core RS CSS file. 95% of time you shouldn't change anything here. */
.royal-slider {
  width: 100%;
  min-height: 463px;
  position: relative;
  direction: ltr;
}

.royal-slider > * {
  float: left;
}

.rsWebkit3d .rsSlide {
  -webkit-transform: translateZ(0);
}

.rsWebkit3d .rsSlide,
.rsWebkit3d .rsContainer,
.rsWebkit3d .rsThumbs,
.rsWebkit3d .rsPreloader,
.rsWebkit3d img,
.rsWebkit3d .rsOverflow,
.rsWebkit3d .rsBtnCenterer,
.rsWebkit3d .rsAbsoluteEl,
.rsWebkit3d .rsABlock,
.rsWebkit3d .rsLink {
  -webkit-backface-visibility: hidden;
}

.rsFade.rsWebkit3d .rsSlide,
.rsFade.rsWebkit3d img,
.rsFade.rsWebkit3d .rsContainer {
  -webkit-transform: none;
}

.rsOverflow {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  float: left;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsVisibleNearbyWrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  left: 0;
  top: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsVisibleNearbyWrap .rsOverflow {
  position: absolute;
  left: 0;
  top: 0;
}

.rsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsArrow,
.rsThumbsArrow {
  cursor: pointer;
}

.rsThumb {
  float: left;
  position: relative;
}

.rsArrow,
.rsNav,
.rsThumbsArrow {
  opacity: 1;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.rsHidden {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.3s,opacity 0.3s linear;
  -moz-transition: visibility 0s linear 0.3s,opacity 0.3s linear;
  -o-transition: visibility 0s linear 0.3s,opacity 0.3s linear;
  transition: visibility 0s linear 0.3s,opacity 0.3s linear;
}

.rsGCaption {
  width: 100%;
  float: left;
  text-align: center;
}

/* Fullscreen options, very important ^^ */
.royal-slider.rsFullscreen {
  position: fixed !important;
  height: auto !important;
  width: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 2147483647 !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
}

.royal-slider .rsSlide.rsFakePreloader {
  opacity: 1 !important;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  display: none;
}

.rsSlide {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.royal-slider.rsAutoHeight,
.rsAutoHeight .rsSlide {
  height: auto;
}

.rsContent {
  width: 100%;
  height: 100%;
  position: relative;
}

.rsPreloader {
  position: absolute;
  z-index: 0;
}

.rsNav {
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  user-select: none;
}

.rsNavItem {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.25);
}

.rsThumbs {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  float: left;
  z-index: 22;
}

.rsTabs {
  float: left;
  background: none !important;
}

.rsTabs,
.rsThumbs {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rsVideoContainer {
  /*left: 0;
	top: 0;
	position: absolute;*/
  /*width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	float: left;*/
  width: auto;
  height: auto;
  line-height: 0;
  position: relative;
}

.rsVideoFrameHolder {
  position: absolute;
  left: 0;
  top: 0;
  background: #141414;
  opacity: 0;
  -webkit-transition: .3s;
}

.rsVideoFrameHolder.rsVideoActive {
  opacity: 1;
}

.rsVideoContainer iframe,
.rsVideoContainer video,
.rsVideoContainer embed,
.rsVideoContainer .rsVideoObj {
  position: absolute;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/* ios controls over video bug, shifting video */
.rsVideoContainer.rsIOSVideo iframe,
.rsVideoContainer.rsIOSVideo video,
.rsVideoContainer.rsIOSVideo embed {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 44px;
}

.rsABlock {
  left: 0;
  top: 0;
  position: absolute;
  z-index: 15;
}

img.rsImg {
  max-width: none;
}

.grab-cursor {
  cursor: url("./img/rs/grab.png") 8 8, move;
}

.grabbing-cursor {
  cursor: url("./img/rs/grabbing.png") 8 8, move;
}

.rsNoDrag {
  cursor: auto;
}

.rsLink {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 20;
  background: url("./img/rs/blank.gif");
}

/******************************
*
*  RoyalSlider Default Skin
*
*    1. Arrows
*    2. Bullets
*    3. Thumbnails
*    4. Tabs
*    5. Fullscreen button
*    6. Play/close video button
*    7. Preloader
*    8. Caption
*
*  Sprite: 'rs-default.png'
*  Feel free to edit anything
*  If you don't some part - just delete it
*
******************************/
/* Background */
.rsDefault,
.rsDefault .rsOverflow,
.rsDefault .rsSlide,
.rsDefault .rsVideoFrameHolder,
.rsDefault .rsThumbs {
  background: #151515;
  color: #FFF;
}

/***************
*
*  1. Arrows
*
****************/
.rsDefault .rsArrow {
  height: 100%;
  width: 44px;
  position: absolute;
  display: block;
  cursor: pointer;
  z-index: 21;
}

.rsDefault.rsVer .rsArrow {
  width: 100%;
  height: 44px;
}

.rsDefault.rsVer .rsArrowLeft {
  top: 0;
  left: 0;
}

.rsDefault.rsVer .rsArrowRight {
  bottom: 0;
  left: 0;
}

.rsDefault.rsHor .rsArrowLeft {
  left: 0;
  top: 0;
}

.rsDefault.rsHor .rsArrowRight {
  right: 0;
  top: 0;
}

.rsDefault .rsArrowIcn {
  width: 32px;
  height: 32px;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
  position: absolute;
  cursor: pointer;
  background: url("./img/rs/rs-default.png");
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  *background-color: #000;
  border-radius: 2px;
}

.rsDefault .rsArrowIcn:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.rsDefault.rsHor .rsArrowLeft .rsArrowIcn {
  background-position: -64px -32px;
}

.rsDefault.rsHor .rsArrowRight .rsArrowIcn {
  background-position: -64px -64px;
}

.rsDefault.rsVer .rsArrowLeft .rsArrowIcn {
  background-position: -96px -32px;
}

.rsDefault.rsVer .rsArrowRight .rsArrowIcn {
  background-position: -96px -64px;
}

.rsDefault .rsArrowDisabled .rsArrowIcn {
  opacity: .2;
  filter: alpha(opacity=20);
  *display: none;
}

/***************
*
*  2. Bullets
*
****************/
.rsDefault .rsBullets {
  position: absolute;
  z-index: 35;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  margin: 0 auto;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  text-align: center;
  line-height: 8px;
  overflow: hidden;
}

.rsDefault .rsBullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 6px 5px 6px;
}

.rsDefault .rsBullet span {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #777;
  background: rgba(255, 255, 255, 0.5);
}

.rsDefault .rsBullet.rsNavSelected span {
  background-color: #FFF;
}

/***************
*
*  3. Thumbnails
*
****************/
.rsDefault .rsThumbsHor {
  width: 100%;
  height: 72px;
}

.rsDefault .rsThumbsVer {
  width: 96px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.rsDefault.rsWithThumbsHor .rsThumbsContainer {
  position: relative;
  height: 100%;
}

.rsDefault.rsWithThumbsVer .rsThumbsContainer {
  position: relative;
  width: 100%;
}

.rsDefault .rsThumb {
  float: left;
  overflow: hidden;
  width: 96px;
  height: 72px;
}

.rsDefault .rsThumb img {
  width: 100%;
  height: 100%;
}

.rsDefault .rsThumb.rsNavSelected {
  background: #02874a;
}

.rsDefault .rsThumb.rsNavSelected img {
  opacity: 0.3;
  filter: alpha(opacity=30);
}

.rsDefault .rsTmb {
  display: block;
}

/* Thumbnails with text */
.rsDefault .rsTmb h5 {
  font-size: 16px;
  margin: 0;
  padding: 0;
  line-height: 20px;
  color: #FFF;
}

.rsDefault .rsTmb span {
  color: #DDD;
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 18px;
}

/* Thumbnails arrow icons */
.rsDefault .rsThumbsArrow {
  height: 100%;
  width: 20px;
  position: absolute;
  display: block;
  cursor: pointer;
  z-index: 21;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
}

.rsDefault .rsThumbsArrow:hover {
  background: rgba(0, 0, 0, 0.9);
}

.rsDefault.rsWithThumbsVer .rsThumbsArrow {
  width: 100%;
  height: 20px;
}

.rsDefault.rsWithThumbsVer .rsThumbsArrowLeft {
  top: 0;
  left: 0;
}

.rsDefault.rsWithThumbsVer .rsThumbsArrowRight {
  bottom: 0;
  left: 0;
}

.rsDefault.rsWithThumbsHor .rsThumbsArrowLeft {
  left: 0;
  top: 0;
}

.rsDefault.rsWithThumbsHor .rsThumbsArrowRight {
  right: 0;
  top: 0;
}

.rsDefault .rsThumbsArrowIcn {
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  position: absolute;
  cursor: pointer;
  background: url("./img/rs/rs-default.png");
}

.rsDefault.rsWithThumbsHor .rsThumbsArrowLeft .rsThumbsArrowIcn {
  background-position: -128px -32px;
}

.rsDefault.rsWithThumbsHor .rsThumbsArrowRight .rsThumbsArrowIcn {
  background-position: -128px -48px;
}

.rsDefault.rsWithThumbsVer .rsThumbsArrowLeft .rsThumbsArrowIcn {
  background-position: -144px -32px;
}

.rsDefault.rsWithThumbsVer .rsThumbsArrowRight .rsThumbsArrowIcn {
  background-position: -144px -48px;
}

.rsDefault .rsThumbsArrowDisabled {
  display: none !important;
}

/* Thumbnails resizing on smaller screens */
@media screen and (min-width: 0px) and (max-width: 800px) {
  .rsDefault .rsThumb {
    width: 59px;
    height: 44px;
  }
  .rsDefault .rsThumbsHor {
    height: 44px;
  }
  .rsDefault .rsThumbsVer {
    width: 59px;
  }
}

/***************
*
*  4. Tabs
*
****************/
.rsDefault .rsTabs {
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  padding-top: 12px;
  position: relative;
}

.rsDefault .rsTab {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  height: auto;
  width: auto;
  color: #333;
  padding: 5px 13px 6px;
  min-width: 72px;
  border: 1px solid #D9D9DD;
  border-right: 1px solid #f5f5f5;
  text-decoration: none;
  background-color: #FFF;
  background-image: -webkit-linear-gradient(top, #fefefe, #f4f4f4);
  background-image: -moz-linear-gradient(top, #fefefe, #f4f4f4);
  background-image: linear-gradient(to bottom, #fefefe, #f4f4f4);
  -webkit-box-shadow: inset 1px 0 0 #fff;
  box-shadow: inset 1px 0 0 #fff;
  *display: inline;
  *zoom: 1;
}

.rsDefault .rsTab:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rsDefault .rsTab:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid #cfcfcf;
}

.rsDefault .rsTab:active {
  border: 1px solid #D9D9DD;
  background-color: #f4f4f4;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2) inset;
}

.rsDefault .rsTab.rsNavSelected {
  color: #FFF;
  border: 1px solid #999;
  text-shadow: 1px 1px #838383;
  box-shadow: 0 1px 9px rgba(102, 102, 102, 0.65) inset;
  background: #ACACAC;
  background-image: -webkit-linear-gradient(top, #ACACAC, #BBB);
  background-image: -moz-llinear-gradient(top, #ACACAC, #BBB);
  background-image: linear-gradient(to bottom, #ACACAC, #BBB);
}

/***************
*
*  5. Fullscreen button
*
****************/
.rsDefault .rsFullscreenBtn {
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  z-index: 22;
  display: block;
  position: absolute;
  cursor: pointer;
}

.rsDefault .rsFullscreenIcn {
  display: block;
  margin: 6px;
  width: 32px;
  height: 32px;
  background: url("./img/rs/rs-default.png") 0 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  *background-color: #000;
  border-radius: 2px;
}

.rsDefault .rsFullscreenIcn:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.rsDefault.rsFullscreen .rsFullscreenIcn {
  background-position: -32px 0;
}

/***************
*
*  6. Play/close video button
*
****************/
.rsDefault .rsPlayBtn {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
  width: 64px;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  cursor: pointer;
}

.rsDefault .rsPlayBtnIcon {
  width: 64px;
  display: block;
  height: 64px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
  background: url("./img/rs/rs-default.png") no-repeat 0 -32px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  *background-color: #000;
}

.rsDefault .rsPlayBtn:hover .rsPlayBtnIcon {
  background-color: rgba(0, 0, 0, 0.9);
}

.rsDefault .rsBtnCenterer {
  position: absolute;
  left: 50%;
  top: 50%;
}

.rsDefault .rsCloseVideoBtn {
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  z-index: 500;
  position: absolute;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
}

.rsDefault .rsCloseVideoBtn.rsiOSBtn {
  top: -38px;
  right: -6px;
}

.rsDefault .rsCloseVideoIcn {
  margin: 6px;
  width: 32px;
  height: 32px;
  background: url("./img/rs/rs-default.png") -64px 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  *background-color: #000;
}

.rsDefault .rsCloseVideoIcn:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

/***************
*
*  7. Preloader
*
****************/
.rsDefault .rsPreloader {
  width: 20px;
  height: 20px;
  background-image: url("./img/rs/preloader-white.gif");
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
}

/***************
*
*  8. Global caption
*
****************/
.rsDefault .rsGCaption {
  position: absolute;
  float: none;
  bottom: 6px;
  left: 6px;
  text-align: left;
  background: black;
  background: rgba(0, 0, 0, 0.75);
  color: #FFF;
  padding: 2px 8px;
  width: auto;
  font-size: 12px;
  border-radius: 2px;
}

/**
 * Table of Contents:
 *
 * 1.0 Content Module
 *   1.1 Stages
 *     1.1.1 Stage Start
 *     1.1.2 Stage Ressort
 *     1.1.3 Stage Ressort Coop
 *   1.2 Galerie
 *   1.3 Bild & Text
 *     1.3.1 Bild & Text – V1 & V2
 *     1.3.2 Bild & Text – Double
 *     1.3.3 Bild & Text – Triple
 *     1.3.4 Bild & Text – Centered
 *   1.4 News
 *   1.5 Slogan/Zitat
 *   1.6 Ansprechpartner
 *     1.6.1 Ansprechpartner – Double
 *   1.7 Terminkalender
 *   1.8 Timeline
 *   1.9 Text
 *     1.9.1 Text – Double
 *   1.10 Accordion
 * 2.0 Blog
 *   2.1 Overview
 *   2.2 Single
 * 3.0 Responsive
 *   3.1 HD
 *   3.2 Desktop
 *   3.3 Tablet
 *   3.4 Mobile
 * -----------------------------------------------------------------------------
 */
/*@import "compass/css3/box-sizing";
@import "compass/css3/transition";*/
/**
 * 1.0 Content Module
 * -----------------------------------------------------------------------------
 */
.image-text-multi,
.slogan-quotation {
  margin-left: -1%;
  margin-right: -1%;
}

.rsFullscreenIcn {
  display: none !important;
}

.content-module-block {
  position: relative;
  /* @todo: nur zu Debug-Zwecken */
  z-index: 0;
  clear: both;
  margin-bottom: 70px;
  /* => $margin-large => $margin-medium => $margin-small  */
}

.content-module-block:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  line-height: 1px;
}

.content-module-block .block-headline {
  padding-left: 1%;
}

.content-module-block .link-list {
  float: right;
  text-align: right;
}

.content-module-block .link-list .link-list-item-link {
  font-size: 14px;
  text-transform: uppercase;
}

.content-module-block .overlay-link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  overflow: hidden;
  text-indent: -10000px;
}

.content-module-block .text-content {
  margin-bottom: 32px;
}

.content-module-block.signet {
  margin-bottom: 35px;
}

.content-module-block.signet .signet {
  margin-bottom: 0;
}

.news,
.stage-start,
.stage-ressort,
.stage-ressort-coop,
.gallery,
.text-columns,
.video {
  width: 100vw;
  position: relative;
  margin-left: calc(-50vw + 50%);
}

.stage-start .col-centered,
.stage-ressort .col-centered,
.stage-ressort-coop .col-centered {
  padding-left: 0;
  padding-right: 0;
}

.posts .col {
  float: right;
}

/**
 * 1.1 Stages
 * -----------------------------------------------------------------------------
 */
.stage-ressort,
.stage-start {
  overflow: hidden;
  max-height: 632px;
}

.stage-start .container,
.stage-ressort .container,
.stage-ressort-coop .container {
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0;
}

.stage-start .mainstage-background,
.stage-ressort .ressorts-background,
.stage-ressort-coop .ressorts-background {
  width: 100%;
}

.stage-start {
  position: relative;
}

.stage-start .outer-container {
  position: absolute;
  bottom: 50px;
  width: 100%;
}

.stage-start .mainstage-label {
  position: relative;
  width: 66.66667%;
  padding: 22px 63px 22px 35px;
  overflow: hidden;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.8);
}

.stage-start .mainstage-label.is--link:after {
  content: '';
  position: absolute;
  width: 63px;
  height: 100%;
  top: 0;
  right: 0;
  background: url("../../../img/arrow-slider-link.png") no-repeat center;
}

.stage-start .mainstage-label h3 {
  line-height: 33px;
}

.stage-start .mainstage-label .headline {
  display: block;
}

.stage-start .mainstage-label .subline {
  display: block;
}

.stage-ressort {
  position: relative;
}

.stage-ressort .headline {
  margin-bottom: 18px;
}

.stage-ressort .subline {
  margin-top: 0;
}

.stage-ressort .ressorts-label {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 28px 0 25px;
  background-color: #f6f5f0;
  background-color: rgba(246, 245, 240, 0.9);
}

.stage-ressort .ressort-logo {
  float: right;
  width: 189px;
  height: 142px;
  margin-left: 30px;
}

.stage-ressort .ressort-logo img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.stage-ressort-coop.contrast-dark .ressorts-label h1,
.stage-ressort-coop.contrast-dark .ressorts-label .subline {
  color: #000000;
}

.stage-ressort-coop.contrast-light .ressorts-label h1,
.stage-ressort-coop.contrast-light .ressorts-label .subline {
  color: #ffffff;
}

/**
 * 1.1a Video
 * -----------------------------------------------------------------------------
 */
html .content-module-block.video .container {
  padding-left: 3%;
  padding-right: 3%;
}

.mod-video {
  height: 0;
  padding-top: 0;
  padding-bottom: 56%;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.mod-video embed,
.mod-video iframe,
.mod-video object,
.mod-video video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

/**
 * 1.2 Gallerie
 * -----------------------------------------------------------------------------
 */
.content-module-block.gallery h2 {
  padding-top: 14px;
}

.royal-slider {
  box-sizing: content-box;
}

.royal-slider.rs-not-ready {
  display: none;
}

.rsDefault .rsThumb img {
  width: auto !important;
}

.royal-slider.rsFullscreen {
  background: #F2F2F2;
}

.rsDefault,
.rsDefault .rsOverflow,
.rsDefault .rsSlide,
.rsDefault .rsVideoFrameHolder,
.rsDefault .rsThumbs {
  background-color: transparent;
}

.rsDefault .rsArrowIcn {
  width: 46px;
  height: 46px;
  margin-top: -23px;
  margin-left: -23px;
}

.rsDefault.rsHor .rsArrowLeft .rsArrowIcn {
  background: url("./img/ico_rs_arrow_left.png") no-repeat center;
}

.rsDefault.rsHor .rsArrowRight .rsArrowIcn {
  background: url("./img/ico_rs_arrow_right.png") no-repeat center;
}

.rsDefault .rsFullscreenIcn {
  width: 25px;
  height: 25px;
  margin: 9px;
  background: url("./img/ico_rs_fullscreen.png") no-repeat center transparent;
}

.rsDefault .rsThumbsArrow {
  width: 28px;
  background-color: #ffffff;
}

.rsDefault .rsThumbsArrowIcn {
  width: 28px;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  position: static;
}

.rsDefault.rsWithThumbsHor .rsThumbsArrowLeft .rsThumbsArrowIcn {
  background: url("./img/ico_rs_thumb_left.png") no-repeat center;
}

.rsDefault.rsWithThumbsHor .rsThumbsArrowRight .rsThumbsArrowIcn {
  background: url("./img/ico_rs_thumb_right.png") no-repeat center;
}

.rsDefault .rsThumbsArrowDisabled {
  display: block !important;
  opacity: 0.2;
}

.rsDefault .rsThumbsHor {
  height: 85px;
  margin: 50px auto 0;
}

.rsDefault.rsWithThumbsHor .rsThumbsContainer {
  margin: 0 28px 0 -10px;
}

.rsDefault .rsThumb {
  height: 85px;
  width: auto;
}

.rsDefault .rsThumb.rsNavSelected {
  background-color: #000000;
}

.gallery .rsDefault .rsGCaption {
  background: transparent;
  width: 66.667%;
  left: 50%;
  bottom: 53px;
  margin-left: -33.334%;
}

.rsDefault .rsGCaption img {
  display: none;
  visibility: hidden;
}

.rsSlide {
  /*width: 66.667%;*/
}

.rsDefault .rsBullets {
  position: static;
  background: transparent;
  margin: 2% 0 2%;
}

.rsDefault .rsBullet span {
  background-color: #DDDDDD;
}

.rsDefault .rsBullet.rsNavSelected span {
  background-color: #606060;
}

/**
 * 1.3 Bild & Text
 * -----------------------------------------------------------------------------
 */
.image-text-centered .col-8.col-centered {
  padding-left: 0;
  padding-right: 0;
}

.image-text-centered .col-6 {
  padding-left: 1.5%;
  padding-right: 1.5%;
}

.image-text-multi .image-text-item {
  position: relative;
}

.image-text-multi .image-container {
  margin-bottom: 12px;
}

.image-text-multi .headline {
  margin-bottom: 10px;
  hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

/**
 * 1.4 News
 * -----------------------------------------------------------------------------
 */
.news {
  background-color: #F2F2F2;
  padding-top: 64px;
}

.news .section-headline, .news .text-columns .headline, .text-columns .news .headline, .news .sidebar-header {
  margin-bottom: 64px;
}

.news .news-item {
  position: relative;
  width: 49.0%;
  padding: 0;
}

.news .news-item.pos-1 {
  margin-left: 2%;
}

.news .news-headline {
  color: #000000;
  display: block;
  margin-bottom: 23px;
}

.news .news-item p:after {
  content: '»';
  color: #000000;
}

.news .news-item .news-thumb-wrapper {
  padding: 0;
  margin-bottom: 0;
}

.news .news-item .news-thumb-wrapper img {
  width: 100%;
}

.news .news-item .news-content-wrapper {
  padding-left: 4%;
}

.news .more-news {
  display: block;
  clear: both;
  text-align: right;
  margin-bottom: 44px;
  text-decoration: underline;
  text-transform: uppercase;
}

.news .more-news:after {
  content: ' »';
}

/**
 * 1.5 Slogan/Zitat
 * -----------------------------------------------------------------------------
 */
.slogan-quotation {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.34px;
}

.slogan-quotation blockquote {
  font-style: normal;
  margin-bottom: 38px;
}

.slogan-quotation blockquote p {
  quotes: "»" "«";
  margin-bottom: 20px;
  font-size: 38px;
  line-height: 46px;
  letter-spacing: 0;
  hyphens: none;
  -webkit-hyphens: none;
  -moz-hyphens: none;
}

.slogan-quotation blockquote p:before {
  content: open-quote;
}

.slogan-quotation blockquote p:after {
  content: close-quote;
}

.slogan-quotation blockquote .cited {
  font-style: normal;
  text-transform: uppercase;
}

.slogan-quotation .link {
  text-transform: uppercase;
}

.slogan-quotation .link a {
  text-decoration: underline;
  position: relative;
}

.slogan-quotation .link a:after {
  content: '»';
  display: block;
  width: 1em;
  position: absolute;
  top: -1px;
  right: -16px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
}

.slogan-quotation .link a,
.slogan-quotation .link a:after {
  transition: color 0.3s ease;
}

.slogan-quotation .link a:hover, .slogan-quotation .link #Navigation .menu-item.current-menu-parent > a, #Navigation .slogan-quotation .link .menu-item.current-menu-parent > a, .slogan-quotation .link #Navigation .menu-item.current-menu-item > a, #Navigation .slogan-quotation .link .menu-item.current-menu-item > a,
.slogan-quotation .link a:hover:after,
.slogan-quotation .link #Navigation .menu-item.current-menu-parent > a:after,
#Navigation .slogan-quotation .link .menu-item.current-menu-parent > a:after,
.slogan-quotation .link #Navigation .menu-item.current-menu-item > a:after,
#Navigation .slogan-quotation .link .menu-item.current-menu-item > a:after {
  color: #A9A8A2;
}

/**
 * 1.7 Text-Columns
 * -----------------------------------------------------------------------------
 */
.text-columns {
  background-color: #F2F2F2;
  padding: 70px 0;
}

.text-columns .headline {
  margin-bottom: 70px;
}

/**
 * 1.8 Timeline
 * -----------------------------------------------------------------------------
 */
.timeline .time-bar-list {
  background: url("./img/bg_timeline_line.png") repeat-y center top transparent;
}

.timeline .time-bar-list:before {
  content: '';
  position: relative;
  top: -11px;
  z-index: 1000;
  display: block;
  width: 11px;
  height: 11px;
  margin: 0 auto;
  background: url("./img/ico_timeline_dot.png") no-repeat center top transparent;
}

.timeline .time-bar-element {
  clear: both;
  list-style: none;
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}

.timeline .year {
  position: absolute;
  top: 50px;
  left: 50%;
  display: inline-block;
  width: 150px;
  margin: 0 0 0 -75px;
  font-size: 48px;
  line-height: 112px;
  text-decoration: none;
  background-color: #ffffff;
}

.timeline .year:hover {
  color: #000000;
  text-decoration: none;
}

.timeline .closed .year:before {
  content: '+';
  position: absolute;
  left: -20px;
  font-size: 40px;
  line-height: 54px;
  border: 1px solid #000000;
}

.timeline .position-right.closed .year:before {
  left: auto;
  right: -20px;
}

.timeline .year-content {
  float: left;
  width: 40%;
  /* eigentlich 43%, aber das passt auf dem Tablet nicht  */
  padding: 50px 1em;
  line-height: 28px;
  letter-spacing: 0;
}

.timeline .position-right .year-content {
  float: right;
}

.timeline .year-image {
  margin-bottom: 42px;
}

/**
 * 1.10 Accordion
 * -----------------------------------------------------------------------------
 */
.accordion p {
  margin-top: 1em;
}

.accordion .accordion-toggler {
  cursor: pointer;
}

.accordion .accordion-list {
  background-color: #F2F2F2;
  margin-top: 28px;
}

.accordion .accordion-item {
  list-style: none;
  padding: 32px 32px 34px;
  border-top: 1px solid #DDDDDD;
}

.accordion .accordion-item:first-child {
  border-top: none;
}

.accordion .accordion-item-headline {
  background: no-repeat right center;
  padding-right: 30px;
  margin-bottom: 0;
}

.accordion .accordion-item.open .accordion-item-headline {
  background-image: url("./img/bg_accordion_open.png");
}

.accordion .accordion-item.close .accordion-item-headline {
  background-image: url("./img/bg_accordion_close.png");
}

.accordion .accordion-item-content ul {
  padding-left: 2em;
  margin: 1em 0;
}

.accordion .accordion-item-content ul li {
  list-style: circle;
}

.eventlist .event, .search-results article {
  clear: left;
  position: relative;
  padding: 25px 0;
  transition: opacity 175ms;
}

.eventlist .event:hover, .search-results article:hover {
  opacity: 0.7;
}

.eventlist .event:before, .search-results article:before {
  content: '';
  display: block;
  height: 1px;
  background-color: #DDDDDD;
  position: absolute;
  top: 0;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.eventlist .event:first-child:before, .search-results article:first-child:before {
  display: none;
}

.eventlist .event .overlay-link:after, .search-results article .overlay-link:after {
  font-family: 'Arial', sans-serif;
  content: '';
  display: block;
  width: 11px;
  height: 19px;
  text-align: center;
  position: absolute;
  z-index: 10000;
  top: 50%;
  margin-top: -15px;
  right: 5%;
  text-indent: 0;
  background-image: url("../../../img/ico_event_arrow.png");
  background-repeat: no-repeat;
  background-position: center;
}

.eventlist .intro,
.eventlist .section-headline,
.eventlist .text-columns .headline,
.text-columns .eventlist .headline,
.eventlist .sidebar-header {
  text-align: center;
}

.em-booking-form {
  width: 66.66667%;
  margin: 70px auto;
}

.em-booking-form .em-booking-form-details {
  width: auto;
  float: none;
  padding: 0;
}

.em-booking-form .em-booking-form-details p label {
  width: 25%;
  text-align: right;
  padding-right: 16px;
  height: 50px;
  line-height: 50px;
}

.em-booking-form .em-booking-form-details p input,
.em-booking-form .em-booking-form-details p select,
.em-booking-form .em-booking-form-details p textarea {
  box-sizing: border-box;
  width: 50%;
  height: 50px;
  line-height: 22px;
  padding: 14px 20px;
  border: 1px solid black;
}

.em-booking-form .em-booking-form-details p textarea {
  height: 150px;
}

.em-booking-form .em-booking-form-details .em-booking-submit {
  margin-left: 25%;
  width: 50%;
}

.search-results .section-headline, .search-results .text-columns .headline, .text-columns .search-results .headline, .search-results .sidebar-header {
  margin: 70px 0;
}

.search-results article {
  margin: 70px 0;
}

/**
 * 2.0 Blog
 * -----------------------------------------------------------------------------
 */
.sidebar-box {
  padding: 22px 30px;
  margin-bottom: 18px;
}

.sidebar-header {
  margin-bottom: 27px;
}

.sidebar-categories {
  background-color: #000;
}

.sidebar-categories-header {
  color: #FFFEFB;
}

.sidebar-category-link {
  color: #FFFFFF;
  letter-spacing: 0.8px;
  line-height: 36px;
  text-decoration: none;
}

.sidebar-calendar {
  background: #DDDDDD;
}

.sidebar-calendar .calendar-item {
  list-style-type: none;
  margin-top: 42px;
}

.sidebar-calendar .calendar-item:first-child {
  margin-top: 0;
}

.sidebar-calendar .item-headline {
  font-size: 18px;
  color: #000000;
  letter-spacing: 1px;
  line-height: 24px;
}

/**
 * 2.1 Overview
 * -----------------------------------------------------------------------------
 */
.post h2 {
  margin-top: 28px;
}

.post h2 a,
.page h2 a {
  color: #000000;
}

.posts .post p {
  margin-top: 1em;
}

.posts .post {
  margin-bottom: 70px;
}

.post .read-more {
  text-align: right;
}

.post .read-more .read-more-link {
  display: inline-block;
  text-transform: uppercase;
  margin-top: 1em;
  margin-right: 14px;
  position: relative;
}

.post .read-more .read-more-link:after {
  content: '»';
  color: #000000;
  position: absolute;
  top: 0;
  right: -14px;
  line-height: 22px;
  width: 14px;
  display: inline-block;
}

/**
 * 2.2 Single
 * -----------------------------------------------------------------------------
 */
.single-post .entry-header .entry-meta,
.single-post .entry-header .entry-date {
  margin-top: 28px;
  font-size: 18px;
  color: #1E1E1E;
}

.single-post .entry-header .entry-meta {
  text-transform: uppercase;
  margin-bottom: 18px;
}

.single-post .entry-header .entry-meta a {
  text-decoration: none;
}

.single-post .entry-header .entry-meta a:hover, .single-post .entry-header .entry-meta #Navigation .menu-item.current-menu-parent > a, #Navigation .single-post .entry-header .entry-meta .menu-item.current-menu-parent > a, .single-post .entry-header .entry-meta #Navigation .menu-item.current-menu-item > a, #Navigation .single-post .entry-header .entry-meta .menu-item.current-menu-item > a {
  text-decoration: underline;
}

.single-post .category-links-separator {
  color: #DDDDDD;
}

.single-post .cat-links a {
  color: #000000;
}

.single-post .entry-header h2 {
  margin-top: 0;
}

.single-post .aligncenter {
  max-width: 100%;
  margin: 35px auto;
}

.single-post .wp-caption-text {
  line-height: 18px;
}

.single-post blockquote {
  font-style: normal;
  max-width: 75%;
  text-align: center;
  margin: 0 auto 38px;
}

.single-post blockquote p {
  quotes: "»" "«";
  margin-bottom: 20px;
  font-size: 38px;
  line-height: 46px;
  letter-spacing: 0;
  font-style: normal;
}

.single-post blockquote p:before {
  content: open-quote;
}

.single-post blockquote p:after {
  content: close-quote;
}

/**
 * 3.0 Responsive
 * -----------------------------------------------------------------------------
 */
/**
 * 3.1 HD
 * -----------------------------------------------------------------------------
 */
/**
 * 3.1 Desktop
 * -----------------------------------------------------------------------------
 */
/**
 * 3.1 Tablet
 * -----------------------------------------------------------------------------
 */
@media only screen and (max-width: 960px) {
  .stage-start .mainstage-label {
    background-position: 98% center;
  }
  .content-module-block {
    margin-bottom: 50px;
  }
  .stage-ressort,
  .stage-start {
    max-height: none;
  }
  .stage-ressort .ressorts-label {
    position: static;
  }
  .stage-ressort .container, .stage-ressort-coop .container {
    padding: 0;
  }
  .stage-ressort .ressorts-label {
    padding: 24px 18px 24px;
  }
  .stage-ressort-coop .ressorts-label {
    padding: 18px 18px 24px;
  }
  .stage-ressort-coop .ressorts-label .subline {
    font-size: 14px;
    letter-spacing: 0.15px;
    line-height: 18px;
  }
  .stage-ressort .ressort-logo {
    float: none;
    width: 110px;
    height: 80px;
    margin: 0 auto 18px;
  }
  .stage-ressort .subline {
    line-height: 18px;
  }
  .gallery .col.col-centered {
    padding-left: 0;
    padding-right: 0;
  }
  .content-module-block.gallery h2 {
    padding: 14px 18px 0;
    margin-bottom: 0;
  }
  .royal-slider {
    min-height: 363px;
  }
  .rsDefault .rsThumbsHor {
    display: none;
    visibility: hidden;
  }
  .rsDefault .rsOverflow {
    overflow: visible;
    margin-top: 44px;
  }
  .rsDefault .rsFullscreenBtn {
    top: -44px;
  }
  .gallery .rsDefault .rsGCaption {
    position: static;
    margin: 2%;
    padding: 0;
    width: auto;
    clear: both;
  }
  .content-module-block .text-content {
    margin-bottom: 28px;
  }
  .image-text-centered .col-8.col-centered {
    width: 100%;
  }
  .contact-person .contact-person-details,
  .contact-person .has-no-image .contact-person-details {
    width: 100%;
  }
  .contact-person-double .contact-person-details.col {
    width: 100%;
    margin-bottom: 35px;
  }
  .contact-person-double .contact-details {
    margin-bottom: 0;
  }
  .contact-person .contact-person-image-wrapper {
    display: none;
    visibility: hidden;
  }
  .contact-person-double .contact-person-details.col + .contact-person-details.col {
    margin-left: 0;
  }
  .slogan-quotation blockquote p {
    font-size: 30px;
    line-height: 36px;
  }
  .news {
    padding-top: 50px;
  }
  .news {
    padding-left: 4%;
    padding-right: 4%;
  }
  .news .section-headline, .news .text-columns .headline, .text-columns .news .headline, .news .sidebar-header {
    font-size: 20px;
    letter-spacing: 1.19px;
    line-height: 24px;
    margin-bottom: 50px;
  }
  .news .news-item {
    width: 100%;
    float: none;
    margin-bottom: 18px;
  }
  .news .news-item.pos-1 {
    margin-left: 0;
  }
  .news .more-news {
    height: 70px;
    line-height: 20px;
    margin-bottom: 0;
    padding: 17px 35px;
  }
  .news .news-thumb-wrapper {
    width: 33%;
  }
  .timeline .time-bar-list {
    background: none;
  }
  .timeline .time-bar-list:before {
    display: none;
  }
  .timeline .time-bar-element {
    margin-bottom: 35px;
  }
  .timeline .year {
    position: static;
    margin: 0 auto 6px;
    font-size: 38px;
    line-height: 46px;
  }
  .timeline .year-content {
    width: 100%;
    float: none;
    padding: 17px 1em;
  }
  .timeline .year-image-wrapper {
    max-width: 60%;
    margin: 0 auto;
  }
  .sidebar-box {
    padding: 28px 20px;
  }
  .sidebar-categories {
    position: relative;
  }
  .sidebar-categories-header {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
    background: url("./img/ico_arrow_down_white.png") no-repeat center right;
  }
  .sidebar-categories-list {
    display: none;
    position: absolute;
    height: auto;
    left: 0;
    right: 0;
    padding: 22px 30px;
    background-color: #000;
    overflow: auto;
  }
  .sidebar-categories:hover .sidebar-categories-header {
    background-image: url("./img/ico_arrow_up_white.png");
  }
  .sidebar-categories:hover .sidebar-categories-list {
    display: block;
  }
  .sidebar-category-link {
    display: block;
    padding: 3px 0;
    line-height: 1;
  }
  .sidebar-category-separator {
    display: none;
  }
  .single-post {
    width: 100%;
  }
  .single-post blockquote {
    max-width: 90%;
  }
  .single-post blockquote p {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0;
    font-style: normal;
  }
}

/**
 * 3.1 Mobile
 * -----------------------------------------------------------------------------
 */
@media only screen and (max-width: 640px) {
  .content-module-block {
    margin-bottom: 35px;
  }
  .image-text-centered .col-6:first-child {
    margin-bottom: 8px;
  }
  .image-text-centered h2 {
    margin-bottom: 6px;
  }
  .stage-start .outer-container,
  .stage-ressort .ressorts-label {
    position: static;
  }
  .stage-start .container,
  .stage-ressort .container,
  .stage-ressort-coop .container {
    padding: 0;
  }
  .stage-start .mainstage-label {
    width: auto;
    height: auto;
  }
  .stage-ressort .ressorts-label {
    padding: 18px 18px 18px;
  }
  .stage-start .mainstage-label h3 {
    margin-bottom: 0;
  }
  .stage-ressort .ressort-logo {
    float: none;
    width: 110px;
    height: 80px;
    margin: 0 auto 18px;
  }
  .image-text-multi .image-text-item {
    margin-bottom: 35px;
  }
  .slogan-quotation .col-8.col-centered {
    margin-top: -10px;
  }
  .slogan-quotation blockquote p {
    font-size: 30px;
    line-height: 36px;
  }
  .news {
    padding-top: 35px;
  }
  .news .col-centered {
    padding: 0;
  }
  .news .section-headline, .news .text-columns .headline, .text-columns .news .headline, .news .sidebar-header {
    margin-bottom: 35px;
  }
  .news .news-item {
    margin-bottom: 0;
  }
  .news .news-item.pos-1 {
    margin-left: 0;
  }
  .content-module-block.news .news-content-wrapper.col-6 {
    padding-bottom: 26px;
  }
  .news .news-item .news-content-wrapper {
    padding-left: 0;
  }
  .news .news-thumb-wrapper {
    display: none;
    visibility: hidden;
  }
  .news .news-headline, .news .news-item p {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.45px;
  }
  .news .more-news {
    height: 48px;
    line-height: 20px;
    margin-bottom: 0;
    padding: 16px 0;
  }
  .timeline .time-bar-list {
    background: none;
  }
  .timeline .time-bar-list:before {
    display: none;
  }
  .timeline .year {
    position: static;
    font-size: 38px;
    line-height: 46px;
  }
  .timeline .year-content {
    width: 100%;
    float: none;
  }
  .sidebar-categories-list {
    padding-left: 20px;
    padding-right: 20px;
  }
  .posts .col-4 {
    width: 100%;
  }
  .archive .posts {
    padding: 0 3%;
  }
  .single-post .entry-header .entry-meta {
    font-size: 14px;
    color: #1E1E1E;
    letter-spacing: 0.5px;
    line-height: 24px;
  }
  .royal-slider {
    min-height: 300px !important;
  }
  /*.single-post .entry-meta .entry-date,*/
  /*.single-post .entry-meta .cat-links {*/
  /*display: block;*/
  /*}*/
}

/* Global Grid Tablet
=================================================== */
@media only screen and (max-width: 960px) {
  html, body {
    font-size: 16px;
    line-height: 25px;
  }
  h1,
  .heading-1 {
    font-size: 32px;
  }
  h2,
  .heading-2 {
    font-size: 28px;
  }
  h3,
  .heading-3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 12px;
  }
  .section-headline, .text-columns .headline, .sidebar-header {
    font-size: 17px;
    line-height: 32px;
  }
  time,
  .time-like {
    font-size: 21px;
    line-height: 26px;
  }
}

/* Global Grid Smartphone
=================================================== */
@media only screen and (max-width: 640px) {
  html, body {
    font-size: 14px;
    line-height: 22px;
  }
  h1,
  .heading-1 {
    font-size: 22px;
  }
  h2,
  .heading-2 {
    font-size: 20px;
    margin-bottom: 13px;
  }
  h3,
  .heading-3 {
    font-size: 21px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 12px;
  }
  .subline-1 {
    font-size: 16px;
    line-height: 22px;
  }
  .subline-2 {
    font-size: 14px;
    line-height: 18px;
  }
  .subline-3 {
    font-size: 12px;
    line-height: 16px;
  }
  .subline-4 {
    font-size: 10px;
    line-height: 14px;
  }
  .section-headline, .text-columns .headline, .sidebar-header {
    font-size: 16px;
    line-height: 30px;
  }
  p {
    font-size: 15px;
    line-height: 24px;
    margin-top: 18px;
  }
  time,
  .time-like {
    font-size: 18px;
    line-height: 23px;
  }
  .news,
  .stage-start,
  .stage-ressort,
  .stage-ressort-coop,
  .gallery,
  .text-columns {
    margin-left: 0;
  }
  .text-columns {
    padding: 50px 0;
  }
  .text-columns .headline {
    margin-bottom: 35px;
  }
  .text-columns .column-1 .headline {
    margin-top: 50px;
  }
  .timeline .year-image {
    margin-bottom: 17px;
  }
  .eventlist .event {
    text-align: center;
    padding-bottom: 55px;
  }
  .eventlist .event .overlay-link:after {
    top: auto;
    margin: 0 0 0 -15px;
    right: auto;
    left: 50%;
    bottom: 15px;
  }
}

#PageHeader {
  height: 140px;
  padding: 32px 0 24px;
}

#PageHeader .logo {
  max-width: none;
}

#PageHeader .col {
  margin-bottom: 0;
}

#OffCanvasToggle {
  display: none;
}

#TopNav {
  float: right;
  min-height: 27px;
}

#TopNav .menu-item {
  float: left;
  margin-left: 40px;
  line-height: 26px;
}

#TopNav .menu-item a {
  text-decoration: none;
  font-size: 16px;
  color: #ADAFB2;
  letter-spacing: 0.57px;
}

#TopNav .menu-item a:hover, #TopNav #Navigation .menu-item.current-menu-parent > a, #Navigation #TopNav .menu-item.current-menu-parent > a, #TopNav #Navigation .menu-item.current-menu-item > a, #Navigation #TopNav .menu-item.current-menu-item > a {
  text-decoration: underline;
}

#TopNav .search-toggler-item .search-toggler {
  float: right;
  width: 21px;
  height: 21px;
  background: url("../../../img/ico_search.png") no-repeat center;
  overflow: hidden;
  text-indent: -10000px;
}

#TopNav .search-toggler-item .search-form {
  position: relative;
  width: 205px;
  height: 26px;
  margin: 0 12px 0 0;
  display: none;
  float: right;
  background-color: #F2F2F2;
}

#TopNav .search-toggler-item .search-form:after {
  content: '›';
  display: block;
  height: 26px;
  line-height: 24px;
  width: 30px;
  position: absolute;
  font-size: 26px;
  text-align: center;
  top: 0;
  right: 0;
  color: #fff;
  z-index: 5000;
  background-color: #ADAFB2;
}

#TopNav .search-toggler-item .search-form .screen-reader-text {
  display: none;
}

#TopNav .search-toggler-item .search-form .search-submit {
  position: relative;
  display: block;
  height: 26px;
  line-height: 26px;
  width: 30px;
  margin: 0;
  padding: 0;
  float: left;
  border: none;
  text-indent: -10000px;
  overflow: hidden;
  z-index: 10000;
  background-color: transparent;
}

#TopNav .search-toggler-item .search-form .search-field {
  display: block;
  float: left;
  overflow: hidden;
  width: 175px;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  margin: 0;
  padding: 5px;
  border: none;
  outline: none !important;
  background-color: #F2F2F2;
}

#TopNav .search-toggler-item .search-form .search-field:focus {
  background-color: #fff;
  border-bottom: 1px solid #ADAFB2;
}

#MainNav {
  clear: right;
}

#Navigation {
  float: right;
  clear: right;
  height: 28px;
  margin-top: 26px;
}

#Navigation .menu-item {
  float: left;
  margin-left: 35px;
  position: relative;
}

#Navigation .menu-item:hover .sub-menu {
  display: block;
}

#Navigation .menu-item.menu-item-has-children:after {
  content: '';
  display: block;
  height: 31px;
  position: absolute;
  width: 100%;
}

#Navigation .menu-item a {
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 1.12px;
  line-height: 19px;
  text-transform: uppercase;
}

#Navigation .menu-item.no-hover > a {
  color: #000;
  cursor: default;
}

#Navigation .menu-item:last-child .sub-menu {
  left: inherit;
  right: 0;
}

#Navigation .sub-menu {
  display: none;
  min-width: 120%;
  position: absolute;
  z-index: 100000;
  top: 55px;
  left: 0;
  margin-left: -10%;
  padding: 12px 18px;
  background-color: #000;
  white-space: nowrap;
  border-top: none;
}

#Navigation .sub-menu .menu-item {
  float: none;
  margin-left: 0;
}

#Navigation .sub-menu a {
  color: #fff;
  line-height: 34px;
  text-transform: none;
}

#Navigation .sub-menu a:hover, #Navigation .sub-menu .menu-item.current-menu-parent > a, #Navigation .sub-menu .menu-item.current-menu-item > a {
  color: #ADAFB2;
}

@media only screen and (max-width: 1280px) {
  #Navigation .menu-item {
    margin-left: 17px;
  }
  #Navigation .menu-item a {
    font-size: 16px;
  }
}

/* Global Grid Tablet
=================================================== */
@media only screen and (max-width: 960px) {
  .menu-open body {
    overflow: hidden;
    max-height: 100vh;
  }
  #PageHeader {
    height: 120px;
    padding: 18px 0;
    min-width: 100%;
    overflow: hidden;
  }
  .menu-open #PageHeader {
    overflow: visible;
  }
  #PageHeader .col-2 {
    padding-left: 4%;
  }
  #OffCanvasToggle {
    position: absolute;
    top: 0;
    left: 85%;
    display: block;
    height: 120px;
    width: 15%;
    background: url("../../../img/ico_menu_overflow.svg") no-repeat center #fff;
    background-size: 30px;
    transition: left 0.3s ease, right 0.3s ease;
  }
  .menu-open #OffCanvasToggle {
    left: 0;
  }
  #TopNav,
  #Navigation {
    float: none;
  }
  #MainNav {
    position: absolute;
    z-index: 100000;
    top: 0;
    bottom: 0;
    right: -100%;
    width: 85%;
    padding: 35px 4%;
    background-color: #000000;
    overflow: hidden;
    overflow-y: auto;
    transition: left 0.3s ease, right 0.3s ease;
  }
  .menu-open #MainNav {
    top: 0;
    right: 0;
  }
  #MainNav .menu-item {
    float: none !important;
    margin: 0 0 35px 0;
  }
  #Navigation {
    height: auto;
    margin: 0;
  }
  #Navigation .menu-item a {
    text-transform: uppercase;
    display: block;
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: 1.12px;
    line-height: 19px;
    text-decoration: none;
  }
  #Navigation .menu-item.no-hover > a {
    color: #fff;
    cursor: default;
  }
  #Navigation .menu-item .sub-menu {
    display: block;
    margin: 0;
    position: static;
    background-color: transparent;
    border: none;
    padding: 35px 0 0 35px;
  }
  #Navigation .menu-item .search-toggler {
    display: none;
  }
  #Navigation .search-form {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    margin: -35px -5% 0;
    background: #ADAFB2;
  }
  #Navigation .search-form .search-field {
    padding: 0 10px 0 20px;
    margin: 0;
    width: 75%;
    height: 100%;
    border: none;
    background-color: transparent;
    font-size: 20px;
    color: #000;
  }
  #Navigation .search-form .search-field:focus {
    background-color: #ffffff;
  }
  #Navigation .search-form .search-field::-webkit-input-placeholder {
    color: #fff;
    font-weight: 400;
  }
  #Navigation .search-form .search-field:-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
    font-weight: 400;
  }
  #Navigation .search-form .search-field::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
    font-weight: 400;
  }
  #Navigation .search-form .search-field:-ms-input-placeholder {
    color: #fff;
    font-weight: 400;
  }
  #Navigation .search-form .search-submit {
    padding: 0;
    margin: 0;
    width: 20%;
    height: 100%;
    border: none;
    background: url("../../../img/svg/search.svg") no-repeat center transparent;
    background-size: 32px;
    overflow: hidden;
    text-indent: -10000px;
  }
  #TopNav {
    padding-top: 18px;
    border-top: 1px solid #333;
  }
  #TopNav .menu-item {
    height: 18px;
    margin: 0 0 18px 0;
  }
  #TopNav a {
    font-size: 16px;
    color: #ADAFB2;
    letter-spacing: 0.55px;
    line-height: 18px;
    text-decoration: none;
  }
  #TopNav .search-toggler-item .search-form,
  #TopNav .search-toggler-item .search-toggler {
    float: left;
  }
  #TopNav .search-toggler-item .search-form .search-field {
    margin: 0 0 0 18px;
  }
}

/* Global Grid Smartphone
=================================================== */
#PageFooter {
  clear: both;
  color: #ADAFB2;
  background: #000000;
  padding-bottom: 70px;
}

#PageFooter .container.col-12 {
  padding: 0 1%;
}

#PageFooter .footer-bottom .col {
  float: right;
}

.footer-bottom {
  margin-top: 50px;
}

.footer-bottom {
  border-top: 1px solid #333;
  padding-top: 18px;
}

.footer-bottom .col {
  padding: 0;
}

#FooterTopNav {
  text-align: left;
  display: block;
}

#FooterTopNav .menu-item {
  display: inline-block;
  margin-left: 30px;
}

#FooterTopNav .menu-item:first-child {
  margin-left: 0;
}

#FooterTopNav .menu-item a {
  font-size: 20px;
  color: #FFFFFF;
  letter-spacing: 1.12px;
  line-height: 19px;
  text-decoration: none;
  text-transform: uppercase;
}

#FooterTopNav .menu-item a:hover, #FooterTopNav #Navigation .menu-item.current-menu-parent > a, #Navigation #FooterTopNav .menu-item.current-menu-parent > a, #FooterTopNav #Navigation .menu-item.current-menu-item > a, #Navigation #FooterTopNav .menu-item.current-menu-item > a {
  text-decoration: underline;
}

#FooterBottomNav {
  float: right;
}

#FooterBottomNav .menu-item {
  float: left;
  margin-left: 25px;
}

#FooterBottomNav .menu-item a {
  font-size: 16px;
  color: #ADAFB2;
  letter-spacing: 0.55px;
  line-height: 18px;
  text-decoration: none;
}

#FooterBottomNav .menu-item a:hover, #FooterBottomNav #Navigation .menu-item.current-menu-parent > a, #Navigation #FooterBottomNav .menu-item.current-menu-parent > a, #FooterBottomNav #Navigation .menu-item.current-menu-item > a, #Navigation #FooterBottomNav .menu-item.current-menu-item > a {
  text-decoration: underline;
}

.footer_social_icon {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}

#footer_menu {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}

@media only screen and (max-width: 640px) {
  #PageFooter .footer-bottom .col {
    float: none;
  }
  #PageFooter .container.col-12 {
    padding: 0 4%;
  }
  #FooterTopNav,
  #FooterBottomNav {
    float: none;
    text-align: left;
    overflow: auto;
  }
  #FooterTopNav .menu-item,
  #FooterBottomNav .menu-item {
    float: none;
    display: block;
  }
  #FooterTopNav .menu-item {
    margin: 35px 0 0 0;
  }
  #FooterBottomNav .menu-item {
    margin: 18px 0 0 0;
  }
}

.c-cookie {
  align-items: center;
  background-color: #F2F2F2;
  display: flex;
  justify-content: center;
  padding: 18px 35px;
}

@media (max-width: 640px) {
  .c-cookie {
    flex-wrap: wrap;
  }
}

.c-cookie__holder {
  display: none;
  width: 100%;
}

@media (max-width: 960px) {
  .c-cookie__holder {
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 200;
  }
}

.c-cookie__buttonholder {
  align-items: center;
  display: flex;
  padding-left: 18px;
}

.c-cookie__dismiss {
  background-color: #000;
  border: none;
  color: #FFF;
  letter-spacing: 1px;
  line-height: 46px;
  margin-right: 2rem;
  outline: none;
  padding: 0 15px;
  text-decoration: none !important;
  text-transform: uppercase;
}

@media (max-width: 640px) {
  .c-cookie__dismiss {
    margin-top: 16px;
  }
}

.c-cookie__text {
  margin-top: 0;
}

@media (max-width: 960px) {
  .c-cookie__text {
    font-size: 12px;
  }
}

.c-slider {
  max-height: none;
}

.c-slider__slide {
  background-size: cover;
  background-position: top center;
  max-height: 632px;
  width: 100%;
}

.c-slider .mainstage-label {
  bottom: 0;
  left: 0;
}

@media (max-width: 90rem) {
  .c-slider .mainstage-label {
    left: 18%;
  }
}

@media (max-width: 40rem) {
  .c-slider .mainstage-label {
    background-color: transparent;
    left: 0;
  }
}

.c-slider .container {
  margin: 0;
  padding-left: 50px !important;
  max-width: 1440px;
}

@media (max-width: 40rem) {
  .c-slider .container {
    padding-left: 0 !important;
  }
}

button.slick-arrow {
  width: 60px;
  height: 60px;
  background-size: 60px;
  background-position: center center;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: -60px;
  transition: 0.5s;
}

button.slick-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

button.slick-next {
  background-image: url("../../../img/ico_rs_arrow_right_nobg.png");
}

button.slick-prev {
  background-image: url("../../../img/ico_rs_arrow_left_nobg.png");
}

.slick-dots button:before {
  background-color: #000;
  width: 15px;
  height: 15px;
}

.slick-slide img {
  width: 100%;
}

@media (max-height: 740px) and (min-width: 800px) {
  .stage-start .outer-container {
    bottom: 150px;
  }
  .slidercon .container {
    padding-left: 80px !important;
  }
}

@media (max-height: 600px) {
  .stage-start .outer-container {
    bottom: 300px;
  }
}

.slick-list.draggable {
  background-color: #F2F2F2;
}
