

/* Global Grid Tablet
=================================================== */
@media only screen and (max-width: $max-width-tablet) {

    html, body {
        font-size: 16px;
        line-height: 25px;
    }

    h1,
    .heading-1 {
        font-size: 32px;
    }

    h2,
    .heading-2 {
        font-size: 28px;
    }

    h3,
    .heading-3 {
        font-size: 24px;
    }

    h4 {
        font-size: 20px;
    }

    h5 {
        font-size: 16px;
    }

    h6 {
        font-size: 12px;
    }

    .section-headline {
        font-size: 17px;
        line-height: 32px;
    }

    time,
    .time-like {
        font-size: 21px;
        line-height: 26px;
    }

}


/* Global Grid Smartphone
=================================================== */
@media only screen and (max-width: $max-width-phone) {

    html, body {
        font-size: 14px;
        line-height: 22px;
    }

    h1,
    .heading-1 {
        font-size: 22px;
    }

    h2,
    .heading-2 {
        font-size: 20px;
        margin-bottom: $margin-mini - 5;
    }

    h3,
    .heading-3 {
        font-size: 21px;
    }

    h4 {
        font-size: 16px;
    }

    h5 {
        font-size: 14px;
    }

    h6 {
        font-size: 12px;
    }

    .subline-1 {
        font-size: 16px;
        line-height: 22px;
    }

    .subline-2 {
        font-size: 14px;
        line-height: 18px;
    }

    .subline-3 {
        font-size: 12px;
        line-height: 16px;
    }

    .subline-4 {
        font-size: 10px;
        line-height: 14px;
    }

    .section-headline {
        font-size: 16px;
        line-height: 30px;
    }

    p {
        font-size: 15px;
        line-height: 24px;
        margin-top: $margin-mini;
    }

    time,
    .time-like {
        font-size: 18px;
        line-height: 23px;
    }

    .news,
    .stage-start,
    .stage-ressort,
    .stage-ressort-coop,
    .gallery,
    .text-columns {
        margin-left: 0;
    }

    .text-columns {
        padding: $margin-medium 0;
    }

    .text-columns .headline {
        margin-bottom: $margin-small;
    }

    .text-columns .column-1 .headline {
        margin-top: $margin-medium
    }

    .timeline .year-image {
        margin-bottom: $margin-small - $margin-mini;
    }

    .eventlist .event {
        text-align: center;
        padding-bottom: 55px;

        .overlay-link:after {
            top: auto;
            margin: 0 0 0 -15px;
            right: auto;
            left: 50%;
            bottom: 15px;
        }
    }

}