
#PageFooter {
    @extend %disable-margin-collapse;
    clear: both;
    color: $color-cool-grey;
    background: $color-black;
    padding-bottom: $margin-large;

    .container.col-12 {
        padding: 0 1%;
    }

    a {

    }

    .footer-bottom {

        .col {
            float: right;
        }
    }
}

.footer-bottom {
    margin-top: $margin-medium;
}

.footer-bottom {
    border-top: 1px solid #333;
    padding-top: $margin-mini;

    .col {
        padding: 0;
    }
}

#FooterTopNav {
    text-align: left;
    display: block;

    .menu-item {
        display: inline-block;
        margin-left: 30px;

        &:first-child {
            margin-left: 0;
        }

        a {
            // @extend %font-helvetica-neue-light;
            font-size: 20px;
            color: #FFFFFF;
            letter-spacing: 1.12px;
            line-height: 19px;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

#FooterBottomNav {
    float: right;

    .menu-item {
        float: left;
        margin-left: 25px;

        a {
            font-size: 16px;
            color: $color-cool-grey;
            letter-spacing: 0.55px;
            line-height: 18px;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.footer_social_icon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

#footer_menu {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
}

@media only screen and (max-width: $max-width-phone) {

    #PageFooter {

        .footer-bottom {

            .col {
                float: none;
            }
        }

        .container.col-12 {
            padding: 0 4%;
        }
    }

    #FooterTopNav,
    #FooterBottomNav {
        float: none;
        text-align: left;
        overflow: auto; // prevent border collapse

        .menu-item {
            float: none;
            display: block;
        }
    }

    #FooterTopNav {

        .menu-item {
            margin: $margin-small 0 0 0;
        }
    }

    #FooterBottomNav {

        .menu-item {
            margin: $margin-mini 0 0 0;
        }
    }
}
