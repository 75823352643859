html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

#Primary {
    padding-left: 0;
    padding-right: 0;
    @include media(">xsmall") {
        padding-left: 3%;
        padding-right: 3%;
    }
}

/* Global Grid Column Setup
=================================================== */
.col {
    float: left;
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 18px;
}

.col-12 .col-12 {
    padding: 0;
}

.container {
    max-width: 1440px;
    padding: 0 0 0;
    margin: 0 auto;
}

/* Global Grid Columns
=================================================== */
.col-12 {
    width: percentage(12 / 12);
}

.col-11 {
    width: percentage(11 / 12);
}

.col-10 {
    width: percentage(10 / 12);
}

.col-9 {
    width: percentage(9 / 12);
}

.col-8 {
    width: percentage(8 / 12);
}

.col-7 {
    width: percentage(7 / 12);
}

.col-6 {
    width: percentage(6 / 12);
}

.col-5 {
    width: percentage(5 / 12);
}

.col-4 {
    width: percentage(4 / 12);
}

.col-3 {
    width: percentage(3 / 12);
}

.col-2 {
    width: percentage(2 / 12);
}

.col-1 {
    width: percentage(1 / 12);
}

.col-centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
}

.col-fullscreen {
    float: none;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
}

/* Global Grid Tablet
=================================================== */
@media only screen and (max-width: $max-width-tablet) {

    /* Tablet Column Setup
    =================================================== */
    .col {
        padding-left: 1.5%;
        padding-right: 1.5%;
    }

    .col-8.col-centered,
    .col-12.container {
        padding-left: 2%;
        padding-right: 2%;
    }
}

/**



    .gallery .col.col-centered {
         padding-left: 0;
         padding-right: 0;
     }

    .calendar .col-8.col-centered {
        padding: 28px 20px;
    }

    .posts .col {
        float: none;
        width: 100%;
    }

    .posts .col-4 {
        width: 83.333%;
        margin-left: auto;
        margin-right: auto;
    }

    .posts .col-8 {
        margin-top: $margin-medium;
    }

*/

/* Global Grid Smartphone
=================================================== */
@media only screen and (max-width: $max-width-phone) {

    /* Smartphone Column Setup
    =================================================== */
    .col {
        float: none;
        // padding-left: 2%;
        // padding-right: 2%;
        padding: 0;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11 {
        width: 100%;
    }

    .col-8.col-centered,
    .col-12.container {
        padding-left: 4%;
        padding-right: 4%;
    }

}

/**
@media only screen and (max-width: 640px) {

    .content-module-block .col-1,
    .content-module-block .col-2,
    .content-module-block .col-3,
    .content-module-block .col-4,
    .content-module-block .col-5,
    .content-module-block .col-6,
    .content-module-block .col-7,
    .content-module-block .col-8,
    .content-module-block .col-9,
    .content-module-block .col-10,
    .content-module-block .col-11,
    .content-module-block .col-12,
    .content-module-block .col-8.col-centered {
        float: none;
        width: 100%;
    }

    .content-module-block .col-8.col-centered {
        padding-left: 4%;
        padding-right: 4%;
    }

    .content-module-block.text .col-8.col-centered,
    .content-module-block.image-text-centered .col-8.col-centered {
        padding: 0;
    }

    .content-module-block .col .col {
        padding-left: 0;
        padding-right: 0;
    }
*/

/* Global Grid IE Lower 9 Fixing
=================================================== */

.lt-ie9 .container {
    min-width: 768px
}

.lt-ie8 .container {
    min-width: inherit;
    width: 960px;
    padding: 10px 10px 0;
}

.lt-ie8 .col {
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px;
}