/**
 * 4 SLICK SLIDER
 * -----------------------------------------------------------------------------
 */
.slick-prev,
.slick-next {
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    border: 0;
    font-size: 0;
    line-height: 0;
    color: transparent;
    cursor: pointer;
}
.slick-next {
    right: 0;
    left: auto;
}
.slick-disabled {
    opacity: 0.15;
}
.slick-dots {
    margin: 20px 0 0 0;
    padding: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    display: inline-block;
    margin: 0 2px;
    opacity: 0.33;
}
.slick-dots .slick-active {
    opacity: 1;
}
.slick-dots button {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    border: 0;
    font-size: 0;
    line-height: 0;
    color: transparent;
    background: transparent;
    cursor: pointer;
}
.slick-dots button:focus {
    outline: none;
}
.slick-dots button:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #76222F;
    transform: translate(-50%,-50%);
}
button.slick-arrow:before {
    display: none;
}
button.slick-arrow {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    z-index: 2;
}
button.slick-next:before, button.slick-prev:before {
    display: block;
    background-color: transparent;
    color: black;
    width: 20px;
    height: 20px;
    font-size: 2rem;
}

/* SLICK */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    position: relative;
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}