#OffCanvasToggle {
    display: none;
}

#TopNav {
    float: right;
    min-height: 27px;

    .menu-item {
        float: left;
        margin-left: 40px;
        line-height: 26px;

        a {
            // @extend %font-helvetica-neue-light-cond;
            text-decoration: none;
            font-size: 16px;
            color: $color-cool-grey;
            letter-spacing: 0.57px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .search-toggler-item {

        .search-toggler {
            float: right;
            width: 21px;
            height: 21px;
            background: url('../../../img/ico_search.png') no-repeat center;
            overflow: hidden;
            text-indent: -10000px;
        }

        .search-form {
            position: relative;
            width: 205px;
            height: 26px;
            margin: 0 12px 0 0;
            display: none;
            float: right;
            background-color: $color-light-grey;

            &:after {
                content: '›';
                display: block;
                height: 26px;
                line-height: 24px;
                width: 30px;
                position: absolute;
                font-size: 26px;
                text-align: center;
                top: 0;
                right: 0;
                color: #fff;
                z-index: 5000;
                background-color: $color-dark-grey;
            }

            .screen-reader-text {
                display: none;
            }

            .search-submit {
                position: relative;
                display: block;
                height: 26px;
                line-height: 26px;
                width: 30px;
                margin: 0;
                padding: 0;
                float: left;
                border: none;
                text-indent: -10000px;
                overflow: hidden;
                z-index: 10000;
                background-color: transparent;
            }

            .search-field {
                display: block;
                float: left;
                overflow: hidden;
                width: 205px - 30px;
                font-size: 14px;
                height: 26px;
                line-height: 26px;
                margin: 0;
                padding: 5px;
                border: none;
                // border-bottom: 1px solid $color-cool-grey;
                outline: none !important;
                background-color: $color-light-grey;

                &:focus {
                    background-color: #fff;
                    border-bottom: 1px solid $color-cool-grey;
                }
            }
        }
    }
}

#MainNav {
    clear: right;
}

#Navigation {
    float: right;
    clear: right;
    height: 28px;
    margin-top: 26px;

    .menu-item {
        float: left;
        margin-left: $margin-small;
        position: relative;

        &:hover {

            .sub-menu {
                display: block;
            }
        }

        &.menu-item-has-children:after {
            content: '';
            display: block;
            height: 31px;
            position: absolute;
            width: 100%;
        }

        a {
            text-decoration: none;
            font-size: 20px;
            letter-spacing: 1.12px;
            line-height: 19px;
            text-transform: uppercase;
        }

        &.no-hover {
            > a {
                color: #000;
                cursor: default;
            }
        }

        &.current-menu-parent,
        &.current-menu-item {
            > a {
                @extend a:hover;
                // text-decoration: underline;
            }
        }

        &:last-child .sub-menu {
          left: inherit;
          right: 0;
        }
    }

    .sub-menu {
        display: none;
        min-width: 120%;
        position: absolute;
        z-index: 100000;
        top: 55px;
        left: 0;
        margin: {
            left: -10%;
        }
        padding: ($margin-mini - 6px) $margin-mini;
        background-color: #000;
        white-space: nowrap;
        border-top: none;

        .menu-item {
            float: none;
            margin-left: 0;
        }

        a {
            color: #fff;
            line-height: 34px;
            text-transform: none;

            &:hover {
                color: $color-cool-grey;
            }
        }
    }
}

@media only screen and (max-width: 1280px){

    #Navigation {

        .menu-item {
            margin-left: $margin-small - 18;

            a {
                font-size: 16px;
            }
        }
    }

}


/* Global Grid Tablet
=================================================== */
@media only screen and (max-width: $max-width-tablet) {

    .menu-open body {
        overflow: hidden;
        max-height: 100vh;
    }

    #PageHeader {
        height: 84px + ($margin-mini * 2);
        padding: $margin-mini 0;
        // position: relative;
        // z-index: 100000;
        min-width: 100%;
        overflow: hidden;

        .menu-open & {
            overflow: visible;
        }

        .col-2 {
            padding-left: 4%;
        }
    }

    #OffCanvasToggle {
        position: absolute;
        top: 0;
        left: 85%;
        display: block;
        height: 120px;
        width: 15%;
        background: url('../../../img/ico_menu_overflow.svg') no-repeat center #fff;
        background-size: 30px;
        transition: left 0.3s ease, right 0.3s ease;

        .menu-open & {
            left: 0;
        }
    }

    #TopNav,
    #Navigation {
        float: none;
    }

    #MainNav {
        position: absolute;
        z-index: 100000;
        top: 0;
        bottom: 0;
        right: -100%;
        width: 85%;
        padding: $margin-small 4%;
        background-color: #000000;
        overflow: hidden;
        overflow-y: auto;
        transition: left 0.3s ease, right 0.3s ease;

        .menu-open & {
            top: 0;
            right: 0;
        }

        .menu-item {
            float: none !important;
            margin: 0 0 $margin-small 0;
        }
    }

    #Navigation {
        height: auto;
        margin: 0;

        .menu-item {

            a {
                text-transform: uppercase;
                display: block;
                font-size: 18px;
                color: #FFFFFF;
                letter-spacing: 1.12px;
                line-height: 19px;
                text-decoration: none;
            }

            &.no-hover {
                > a {
                    color: #fff;
                    cursor: default;
                }
            }

            .sub-menu {
                display: block;
                margin: 0;
                position: static;
                background-color: transparent;
                border: none;
                padding: $margin-small 0 0 $margin-small;
            }
        }

        .menu-item .search-toggler {
            display: none;
        }

        .search-form {
            height: 60px;
            line-height: 60px;
            overflow: hidden;
            margin: -$margin-small -5% 0;
            background: $color-dark-grey;

            .search-field {
                padding: 0 10px 0 20px;
                margin: 0;
                width: 75%;
                height: 100%;
                border: none;
                background-color: transparent;
                font-size: 20px;
                color: #000;

                &:focus {
                    background-color: #ffffff;
                }

                &::-webkit-input-placeholder {
                    color: #fff;
                    font-weight: 400;
                }

                &:-moz-placeholder { /* Firefox 18- */
                    color: #fff;
                    font-weight: 400;
                }

                &::-moz-placeholder {  /* Firefox 19+ */
                    color: #fff;
                    font-weight: 400;
                }

                &:-ms-input-placeholder {
                    color: #fff;
                    font-weight: 400;
                }
            }

            .search-submit {
                padding: 0;
                margin: 0;
                width: 20%;
                height: 100%;
                border: none;
                background: url('../../../img/svg/search.svg') no-repeat center transparent;
                background-size: 32px;
                overflow: hidden;
                text-indent: -10000px;
            }
        }

    }

    #TopNav {
        padding-top: $margin-mini;
        border-top: 1px solid #333;

        .menu-item {
            height: 18px;
            margin: 0 0 $margin-mini 0;
        }

        a {
            font-size: 16px;
            color: $color-cool-grey;
            letter-spacing: 0.55px;
            line-height: 18px;
            text-decoration: none;
        }

        .search-toggler-item {

            .search-form,
            .search-toggler {
                float: left;
            }

            .search-toggler {

            }

            .search-form {

                .search-field {
                    margin: 0 0 0 $margin-mini;
                }
            }
        }
    }

}

/* Global Grid Smartphone
=================================================== */
@media only screen and (max-width: $max-width-phone) {



}
