/**
 * Table of Contents:
 *
 * 1.0 Content Module
 *   1.1 Stages
 *     1.1.1 Stage Start
 *     1.1.2 Stage Ressort
 *     1.1.3 Stage Ressort Coop
 *   1.2 Galerie
 *   1.3 Bild & Text
 *     1.3.1 Bild & Text – V1 & V2
 *     1.3.2 Bild & Text – Double
 *     1.3.3 Bild & Text – Triple
 *     1.3.4 Bild & Text – Centered
 *   1.4 News
 *   1.5 Slogan/Zitat
 *   1.6 Ansprechpartner
 *     1.6.1 Ansprechpartner – Double
 *   1.7 Terminkalender
 *   1.8 Timeline
 *   1.9 Text
 *     1.9.1 Text – Double
 *   1.10 Accordion
 * 2.0 Blog
 *   2.1 Overview
 *   2.2 Single
 * 3.0 Responsive
 *   3.1 HD
 *   3.2 Desktop
 *   3.3 Tablet
 *   3.4 Mobile
 * -----------------------------------------------------------------------------
 */

/*@import "compass/css3/box-sizing";
@import "compass/css3/transition";*/

/**
 * 1.0 Content Module
 * -----------------------------------------------------------------------------
 */

.image-text-multi,
.slogan-quotation { margin-left: -1%; margin-right: -1%; }
.rsFullscreenIcn { display: none !important; }

.content-module-block {
    position: relative; /* @todo: nur zu Debug-Zwecken */
    z-index: 0;
    clear: both;
    margin-bottom: $margin-large; /* => $margin-large => $margin-medium => $margin-small  */
}

.content-module-block:after /* .clearfix */  {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
    line-height: 1px;
}

.content-module-block .block-headline {
    padding-left: 1%;
}

.content-module-block .link-list {
    float: right;
    text-align: right;

    .link-list-item-link {
        font-size: 14px;
        text-transform: uppercase;
    }
}

.content-module-block .overlay-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    overflow: hidden;
    text-indent: -10000px;
}

.content-module-block .text-content {
    margin-bottom: 32px;
}

.content-module-block .col-8.col-centered {
//    width: 66%;
//    padding-left: 0.5%;
//    padding-right: 0.5%;
}

.content-module-block .col-4 {
    // width: 33%;
}

.content-module-block.signet {
    margin-bottom: 35px;

    .signet {
        margin-bottom: 0;
    }
}

.news,
.stage-start,
.stage-ressort,
.stage-ressort-coop,
.gallery,
.text-columns,
.video {
    width: 100vw;
    position: relative;
    margin-left: calc(-50vw + 50%);
}

.stage-start .col-centered,
.stage-ressort .col-centered,
.stage-ressort-coop .col-centered {
    padding-left: 0;
    padding-right: 0;
}

.posts .col {
    float: right;
}

/**
 * 1.1 Stages
 * -----------------------------------------------------------------------------
 */

.stage-ressort,
.stage-start {
    overflow: hidden;
    max-height: 632px;
}

.stage-start .container,
.stage-ressort .container,
.stage-ressort-coop .container {
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 0;
}

.stage-start .mainstage-background,
.stage-ressort .ressorts-background,
.stage-ressort-coop .ressorts-background {
    width: 100%;
}

.stage-start {
    position: relative;
}

.stage-start .outer-container {
    position: absolute;
    bottom: $margin-medium;
    width: 100%;
}

.stage-start .mainstage-label {
    position: relative;
    width: percentage(8 / 12);
    padding: 22px 63px 22px 35px;
    overflow: hidden;
    background-color: #ffffff;
    background-color: rgba(#ffffff, 0.8);

    &.is--link {
        &:after {
            content: '';
            position: absolute;
            width: 63px;
            height: 100%;
            top: 0;
            right: 0;
            background: url('../../../img/arrow-slider-link.png') no-repeat center;
        }
    }
}

.stage-start .mainstage-label h3 {
    line-height: 33px;
}

.stage-start .mainstage-label .headline {
    display: block;
}

.stage-start .mainstage-label .subline {
    display: block;
}

.stage-ressort {
    position: relative;
    // margin-top: -$margin-mini;

    .headline {
        margin-bottom: $margin-mini;
    }

    .subline {
        margin-top: 0;
    }
}

.stage-ressort .ressorts-label {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 28px 0 25px;
    background-color: #f6f5f0;
    background-color: rgba(#f6f5f0, 0.9);
}

.stage-ressort .ressort-logo {
    float: right;
    width: 189px;
    height: 142px;
    margin-left: 30px;
}

.stage-ressort .ressort-logo img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
}

.stage-ressort-coop.contrast-dark .ressorts-label h1,
.stage-ressort-coop.contrast-dark .ressorts-label .subline {
    color: $font-default-color;
}

.stage-ressort-coop.contrast-light .ressorts-label h1,
.stage-ressort-coop.contrast-light .ressorts-label .subline {
    color: #ffffff;
}

/**
 * 1.1a Video
 * -----------------------------------------------------------------------------
 */
html .content-module-block.video .container {
    padding-left: 3%;
    padding-right: 3%;
}

.mod-video {
    height: 0;
    padding-top: 0;
    padding-bottom: 56%;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;

    embed,
    iframe,
    object,
    video {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
}

/**
 * 1.2 Gallerie
 * -----------------------------------------------------------------------------
 */

.content-module-block.gallery {

}

.content-module-block.gallery h2 {
    padding-top: 14px;
}

.royal-slider {
    box-sizing: content-box;

    &.rs-not-ready {
        display: none;
    }
}

.rsDefault .rsThumb img {
    width: auto !important;
}

.royal-slider.rsFullscreen {
    background: $color-light-grey;
}

.rsDefault, .rsDefault .rsOverflow, .rsDefault .rsSlide, .rsDefault .rsVideoFrameHolder, .rsDefault .rsThumbs {
    background-color: transparent;
}

.rsDefault .rsArrowIcn {
    width: 46px;
    height: 46px;
    margin-top: -23px;
    margin-left: -23px;
}

.rsDefault.rsHor .rsArrowLeft .rsArrowIcn {
    background: url('./img/ico_rs_arrow_left.png') no-repeat center;
}

.rsDefault.rsHor .rsArrowRight .rsArrowIcn {
    background: url('./img/ico_rs_arrow_right.png') no-repeat center;
}

.rsDefault .rsFullscreenIcn {
    width: 25px;
    height: 25px;
    margin: 9px;
    background: url('./img/ico_rs_fullscreen.png') no-repeat center transparent;
}

.rsDefault .rsThumbsArrow {
    width: 28px;
    background-color: #ffffff;
}

.rsDefault .rsThumbsArrowIcn {
    width: 28px;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    position: static;
}

.rsDefault.rsWithThumbsHor .rsThumbsArrowLeft .rsThumbsArrowIcn {
    background: url('./img/ico_rs_thumb_left.png') no-repeat center;
}

.rsDefault.rsWithThumbsHor .rsThumbsArrowRight .rsThumbsArrowIcn {
    background: url('./img/ico_rs_thumb_right.png') no-repeat center;
}

.rsDefault .rsThumbsArrowDisabled {
    display: block !important;
    opacity: 0.2;
}

.rsDefault .rsThumbsHor {
    height: 85px;
    margin: 50px auto 0;
}

.rsDefault.rsWithThumbsHor .rsThumbsContainer {
    margin: 0 28px 0 -10px;
}

.rsDefault .rsThumb {
    height: 85px;
    width: auto;
}

.rsDefault .rsThumb.rsNavSelected {
    background-color: $color-primary;
}

.gallery .rsDefault .rsGCaption {
    @extend .caption;
    background: transparent;
    width: 66.667%;
    left: 50%;
    bottom: 53px;
    margin-left: -33.334%;
}

.rsDefault .rsGCaption img {
    display: none;
    visibility: hidden;
}

.rsSlide {
    /*width: 66.667%;*/
}

.rsDefault .rsBullets {
    position: static;
    background: transparent;
    margin: 2% 0 2%;
}

.rsDefault .rsBullet span {
    background-color: $color-medium-grey;
}

.rsDefault .rsBullet.rsNavSelected span {
    background-color: #606060;
}

/**
 * 1.3 Bild & Text
 * -----------------------------------------------------------------------------
 */

.image-text-centered .col-8.col-centered {
    padding-left: 0;
    padding-right: 0;
}

.image-text-centered .col-6 {
    padding-left: 1.5%;
    padding-right: 1.5%;
}

.image-text-multi .image-text-item {
    position: relative;
}

.image-text-multi .image-container {
    margin-bottom: 12px;
}

.image-text-multi .headline {
    margin-bottom: 10px;
    hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
}

/**
 * 1.4 News
 * -----------------------------------------------------------------------------
 */

.news {
    background-color: $color-light-grey;
    padding-top: $margin-large - 6px;
}

.news .col-6 {

}

.news .section-headline {
    margin-bottom: $margin-large - 6px;
}

.news .news-item {
    position: relative;
    width: 49.0%;
    padding: 0;
}

.news .news-item.pos-1 {
    margin-left: 2%;
}

.news .news-headline {
    @extend %font-helvetica-neue;
    color: $color-primary;
    display: block;
    margin-bottom: $margin-small - 12px;
}

.news .news-item p:after {
    content: '»';
    color: $color-primary;
}

.news .news-item .news-thumb-wrapper {
    padding: 0;
    margin-bottom: 0;
}

.news .news-item .news-thumb-wrapper img {
    width: 100%;
}

.news .news-item .news-content-wrapper {
    padding-left: 4%;
}

.news .more-news {
    display: block;
    clear: both;
    text-align: right;
    margin-bottom: 44px;
    text-decoration: underline;
    text-transform: uppercase;
}

.news .more-news:after {
    content: ' »';
}

/**
 * 1.5 Slogan/Zitat
 * -----------------------------------------------------------------------------
 */

.slogan-quotation {
    @extend %font-sabon-roman;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1.34px;
}

.slogan-quotation blockquote {
    font-style: normal;
    margin-bottom: 38px;
}

.slogan-quotation blockquote p {
    @extend %font-sabon-italic;
    quotes: "»" "«";
    margin-bottom: 20px;
    font-size: 38px;
    line-height: 46px;
    letter-spacing: 0;
    hyphens: none; -webkit-hyphens: none; -moz-hyphens: none;
}

.slogan-quotation blockquote p:before {
    content: open-quote;
}

.slogan-quotation blockquote p:after {
    content: close-quote;
}

.slogan-quotation blockquote .cited {
    font-style: normal;
    text-transform: uppercase;
}

.slogan-quotation .link {
    text-transform: uppercase;
}

.slogan-quotation .link a {
    text-decoration: underline;
    position: relative;
}

.slogan-quotation .link a:after {
    content: '»';
    display: block;
    width: 1em;
    position: absolute;
    top: -1px;
    right: -16px;
//    font-size: 12px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-align: center;
    text-decoration: none;
}

.slogan-quotation .link a,
.slogan-quotation .link a:after {
    transition: color 0.3s ease;
}

.slogan-quotation .link a:hover,
.slogan-quotation .link a:hover:after {
    color: #A9A8A2;
}

/**
 * 1.7 Text-Columns
 * -----------------------------------------------------------------------------
 */

.text-columns {
    background-color: $color-light-grey;
    padding: $margin-large 0;

    .headline {
        @extend .section-headline;
        margin-bottom: $margin-large;
    }
}

/**
 * 1.8 Timeline
 * -----------------------------------------------------------------------------
 */

.timeline .time-bar-list {
    background: url('./img/bg_timeline_line.png') repeat-y center top transparent;
}

.timeline .time-bar-list:before {
    content: '';
    position: relative;
    top: -11px;
    z-index: 1000;
    display: block;
    width: 11px;
    height: 11px;
    margin: 0 auto;
    background: url('./img/ico_timeline_dot.png') no-repeat center top transparent;
}

.timeline .time-bar-element {
    clear: both;
    list-style: none;
    position: relative;
    text-align: center;
    margin-bottom: $margin-medium;
}

.timeline .time-bar-element {
    @extend %clearfix;
}

.timeline .year {
    @extend %font-sabon-roman;
    position: absolute;
    top: $margin-medium;
    left: 50%;
    display: inline-block;
    width: 150px;
    margin: 0 0 0 -75px;
    font-size: 48px;
    line-height: (32px * 2 + 48px);
    text-decoration: none;
    background-color: #ffffff;
}

.timeline .year:hover {
    color: $color-primary;
    text-decoration: none;
}

.timeline .closed .year:before {
    content: '+';
    position: absolute;
    left: -20px;
    font-size: 40px;
    line-height: 54px;
    border: 1px solid $color-primary;
}

.timeline .position-right.closed .year:before {
    left: auto;
    right: -20px;
}

.timeline .year-content {
    float: left;
    width: 40%; /* eigentlich 43%, aber das passt auf dem Tablet nicht  */
    padding: $margin-medium 1em;
    line-height: 28px;
    letter-spacing: 0;
}

.timeline .position-right .year-content {
    float: right;
}

.timeline .year-image {
    margin-bottom: 42px;
}

/**
 * 1.10 Accordion
 * -----------------------------------------------------------------------------
 */

.accordion {

}

.accordion p {
    margin-top: 1em;
}

.accordion .accordion-toggler {
    cursor: pointer;
}

.accordion .accordion-list {
    background-color: $color-light-grey;
    margin-top: 28px;
}

.accordion .accordion-item {
    list-style: none;
    padding: 32px 32px 34px;
    border-top: 1px solid $color-medium-grey;
}

.accordion .accordion-item:first-child {
    border-top: none;
}

.accordion .accordion-item-headline {
    background: no-repeat right center;
    padding-right: 30px;
    margin-bottom: 0;
}

.accordion .accordion-item.open .accordion-item-headline {
    background-image: url('./img/bg_accordion_open.png');
}

.accordion .accordion-item.close .accordion-item-headline {
    background-image: url('./img/bg_accordion_close.png');
}

.accordion .accordion-item-content {

}

.accordion .accordion-item-content ul {
    padding-left: 2em;
    margin: 1em 0;
}

.accordion .accordion-item-content ul li {
    list-style: circle;
}

%large-list-item-with-border {
    @extend %clearfix;
    clear: left;
    position: relative;
    padding: 25px 0;
    transition: opacity 175ms;

    &:hover {
        opacity: 0.7;
    }

    &:before {
        content: '';
        display: block;
        height: 1px;
        background-color: $color-medium-grey;
        position: absolute;
        top: 0;
        width: 100vw;
        margin-left: calc(-50vw + 50%);
    }

    &:first-child:before {
        display: none;
    }

    .overlay-link {

        &:after {
            font-family: 'Arial', sans-serif;
            content: '';
            display: block;
            width: 11px;
            height: 19px;
            text-align: center;
            position: absolute;
            z-index: 10000;
            top: 50%;
            margin-top: -15px;
            right: 5%;
            text-indent: 0;
            background: {
                image: url('../../../img/ico_event_arrow.png');
                repeat: no-repeat;
                position: center;
            };
        }
    }
}

.eventlist {

    .intro,
    .section-headline {
        text-align: center;
    }

    .event {
        @extend %large-list-item-with-border;
    }

}

$booking-form-columns: 8;

.em-booking-form {
    width: percentage($booking-form-columns / 12);
    margin: $margin-large auto;

    .em-booking-form-details {
        width: auto;
        float: none;
        padding: 0;

        p {

            label {
                width: percentage(2 / $booking-form-columns);
                text-align: right;
                padding-right: 16px;
                height: 50px;
                line-height: 50px;
            }

            input,
            select,
            textarea {
                box-sizing: border-box;
                width: percentage(4 / $booking-form-columns);
                height: 50px;
                line-height: 22px;
                padding: 14px 20px;
                border: 1px solid black;
            }

            textarea {
                height: 150px;
            }
        }

        .em-booking-submit {
            @extend .button-primary;

            margin-left: percentage(2 / $booking-form-columns);
            width: percentage(4 / $booking-form-columns);
        }
    }
}

.search-results {

    .section-headline {
        margin: $margin-large 0;
    }

    article {
        @extend %large-list-item-with-border, %clearfix;
        margin: $margin-large 0;
    }
}

/**
 * 2.0 Blog
 * -----------------------------------------------------------------------------
 */

.sidebar-box {
    padding: 22px 30px;
    margin-bottom: $margin-mini;
}

.sidebar-header {
    @extend .section-headline;
    margin-bottom: $margin-small - 8;
}

.sidebar-categories {
    background-color: #000;
}

.sidebar-categories-header {
    color: #FFFEFB;
}

.sidebar-categories-list {

}

.sidebar-category-link {
    color: #FFFFFF;
    letter-spacing: 0.8px;
    line-height: 36px;
    text-decoration: none;
}

.sidebar-calendar {
    background: $color-medium-grey;
}

.sidebar-calendar .calendar-item {
    list-style-type: none;
    margin-top: 42px;
}

.sidebar-calendar .calendar-item:first-child {
    margin-top: 0;
}

.sidebar-calendar .item-headline {
    font-size: $margin-mini;
    color: $font-default-color;
    letter-spacing: 1px;
    line-height: 24px;
}

/**
 * 2.1 Overview
 * -----------------------------------------------------------------------------
 */

.post h2 {
    margin-top: 28px;
}

.post h2 a,
.page h2 a {
    color: $color-primary;
}

.posts .post p {
    margin-top: 1em;
}

.posts .post {
    margin-bottom: $margin-large;
}

.post .read-more {
    text-align: right;
}

.post .read-more .read-more-link {
    display: inline-block;
    text-transform: uppercase;
    margin-top: 1em;
    margin-right: 14px;
    position: relative;
}

.post .read-more .read-more-link:after {
    content: '»';
    color: $color-primary;
    position: absolute;
    top: 0;
    right: -14px;
    line-height: 22px;
    width: 14px;
    display: inline-block;
}

/**
 * 2.2 Single
 * -----------------------------------------------------------------------------
 */

.single-post {

}

.single-post .entry-header {

}

.single-post .entry-header .entry-meta,
.single-post .entry-header .entry-date {
    @extend %font-helvetica-neue;
    margin-top: 28px;
    font-size: $margin-mini;
    color: #1E1E1E;
}

.single-post .entry-header .entry-meta {
    text-transform: uppercase;
    margin-bottom: $margin-mini;

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.single-post .category-links-separator {
    color: $color-medium-grey;
}

.single-post .cat-links a {
    color: $color-primary;
}

.single-post .entry-header h2 {
    margin-top: 0;
}

.single-post .aligncenter {
    max-width: 100%;
    margin: $margin-small auto;
}

.single-post .wp-caption-text {
    @extend .caption;
    line-height: $margin-mini;
}
.single-post  blockquote {
    font-style: normal;
    max-width: 75%;
    text-align: center;
    margin: 0 auto 38px;
}

.single-post  blockquote p {
    @extend %font-sabon-italic;
    quotes: "»" "«";
    margin-bottom: 20px;
    font-size: 38px;
    line-height: 46px;
    letter-spacing: 0;
    font-style: normal;
}

.single-post  blockquote p:before {
    content: open-quote;
}

.single-post  blockquote p:after {
    content: close-quote;
}

/**
 * 3.0 Responsive
 * -----------------------------------------------------------------------------
 */

/**
 * 3.1 HD
 * -----------------------------------------------------------------------------
 */

@media only screen and (max-width: 1560px) {

}

/**
 * 3.1 Desktop
 * -----------------------------------------------------------------------------
 */

@media only screen and (min-width: 960px) and (max-width: 1440px) {


}

/**
 * 3.1 Tablet
 * -----------------------------------------------------------------------------
 */

@media only screen and (max-width: 960px) {

    .stage-start .mainstage-label {
        background-position: 98% center;
    }

    .content-module-block {
        margin-bottom: $margin-medium;
    }

    .stage-ressort, .stage-start {
        max-height: none;
    }

    .stage-ressort .ressorts-label {
        position: static;
    }

    .stage-ressort .container, .stage-ressort-coop .container {
        padding: 0;
    }

    .stage-ressort .ressorts-label {
        padding: 24px $margin-mini 24px;
    }

    .stage-ressort-coop .ressorts-label {
        padding: $margin-mini $margin-mini 24px;
    }

    .stage-ressort-coop .ressorts-label .subline {
        font-size: 14px;
        letter-spacing: 0.15px;
        line-height: $margin-mini;
    }

    .stage-ressort .ressort-logo {
        float: none;
        width: 110px;
        height: 80px;
        margin: 0 auto $margin-mini;
    }

    .stage-ressort .subline {
        line-height: $margin-mini;
    }

    .gallery .col.col-centered {
         padding-left: 0;
         padding-right: 0;
     }

    .content-module-block.gallery h2 {
        padding: 14px $margin-mini 0;
        margin-bottom: 0;
    }

    .royal-slider {
         min-height: 363px;
     }

    .rsDefault .rsThumbsHor {
        display: none;
        visibility: hidden;
    }

    .rsDefault .rsOverflow {
        overflow: visible;
        margin-top: 44px;
    }

    .rsDefault .rsFullscreenBtn {
        top: -44px;
    }

    .gallery .rsDefault .rsGCaption {
        position: static;
        margin: 2%;
        padding: 0;
        width: auto;
        clear: both;
    }

    .content-module-block .text-content {
        margin-bottom: 28px;
    }

    .image-text-centered .col-8.col-centered {
        width: 100%;
    }

    .contact-person .contact-person-details,
    .contact-person .has-no-image .contact-person-details {
        width: 100%;
    }

    .contact-person-double .contact-person-details.col {
        width: 100%;
        margin-bottom: $margin-small;
    }

    .contact-person-double .contact-details {
        margin-bottom: 0;
    }

    .contact-person .contact-person-image-wrapper {
        display: none;
        visibility: hidden;
    }

    .contact-person-double .contact-person-details.col + .contact-person-details.col {
        margin-left: 0;
    }

    .slogan-quotation blockquote p {
        font-size: 30px;
        line-height: 36px;
    }

    .news {
        padding-top: $margin-medium;
    }

    .news {
        padding-left: 4%;
        padding-right: 4%;
    }

    .news .section-headline {
        font-size: 20px;
        letter-spacing: 1.19px;
        line-height: 24px;
        margin-bottom: $margin-medium;
    }

    .news .news-item {
        width: 100%;
        float: none;
        margin-bottom: $margin-mini;
    }

    .news .news-item.pos-1 {
        margin-left: 0;
    }

    .news .news-item:after {
        // @extend %clearfix;
    }

    .news .more-news {
        height: $margin-large;
        line-height: 20px;
        margin-bottom: 0;
        padding: 17px $margin-small;
    }

    .news .news-headline, .news .news-item p {

    }

    .news .news-thumb-wrapper {
        width: 33%;
    }

    .timeline .time-bar-list {
        background: none;
    }

    .timeline .time-bar-list:before {
        display: none;
    }

    .timeline .time-bar-element {
        margin-bottom: $margin-small;
    }

    .timeline .year {
        position: static;
        margin: 0 auto 6px;
        font-size: 38px;
        line-height: 46px;
    }

    .timeline .year-content {
        width: 100%;
        float: none;
        padding: ($margin-small - $margin-mini) 1em;
    }

    .timeline .year-image-wrapper {
        max-width: 60%;
        margin: 0 auto;
    }

    .sidebar-box {
        padding: 28px 20px;
    }

    .sidebar-categories {
        position: relative;
    }

    .sidebar-categories-header {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
        background: url('./img/ico_arrow_down_white.png') no-repeat center right;
    }

    .sidebar-categories-list {
        display: none;
        position: absolute;
        height: auto;
        left: 0;
        right: 0;
        padding: 22px 30px;
        background-color: #000;
        overflow: auto;
    }

    .sidebar-categories:hover .sidebar-categories-header {
        background-image: url('./img/ico_arrow_up_white.png');
    }

    .sidebar-categories:hover .sidebar-categories-list {
        display: block;
    }

    .sidebar-category-link {
        display: block;
        padding: 3px 0;
        line-height: 1;
    }

    .sidebar-category-separator {
        display: none;
    }

    .single-post {
        width: 100%;
    }
    .single-post  blockquote {
        max-width: 90%;
    }

    .single-post blockquote p {
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0;
        font-style: normal;
    }

}

/**
 * 3.1 Mobile
 * -----------------------------------------------------------------------------
 */

@media only screen and (max-width: 640px) {

    .content-module-block {
        margin-bottom: $margin-small;
    }

    .image-text-centered .col-6:first-child {
        margin-bottom: 8px;
    }

    .image-text-centered h2 {
        margin-bottom: 6px;
    }

    .stage-start .outer-container,
    .stage-ressort .ressorts-label {
        position: static;
    }

    .stage-start .container, .stage-ressort .container, .stage-ressort-coop .container {
        padding: 0;
    }

    .stage-start .mainstage-label {
        width: auto;
        height: auto;
    }

    .stage-ressort .ressorts-label {
        padding: $margin-mini $margin-mini $margin-mini;
    }

    .stage-start .mainstage-label h3 {
        margin-bottom: 0;
    }

    .stage-ressort .ressort-logo {
        float: none;
        width: 110px;
        height: 80px;
        margin: 0 auto $margin-mini;
    }

    .image-text-multi .image-text-item {
        margin-bottom: $margin-small;
    }

    .slogan-quotation .col-8.col-centered {
        margin-top: -10px;
    }

    .slogan-quotation blockquote p {
        font-size: 30px;
        line-height: 36px;
    }

    .news {
        padding-top: $margin-small;
    }

    .news .col-centered {
        padding: 0;
    }

    .news .section-headline {
        margin-bottom: $margin-small;
    }

    .news .news-item {
        margin-bottom: 0;
    }

    .news .news-item.pos-1 {
        margin-left: 0;
    }

    .content-module-block.news .news-content-wrapper.col-6 {
        padding-bottom: 26px;
    }

    .news .news-item .news-content-wrapper {
        padding-left: 0;
    }

    .news .news-thumb-wrapper {
        display: none;
        visibility: hidden;
    }

    .news .news-headline, .news .news-item p {
        font-size: 14px;
        line-height: $margin-mini;
        letter-spacing: 0.45px;
    }

    .news .more-news {
        height: 48px;
        line-height: 20px;
        margin-bottom: 0;
        padding: 16px 0;
    }

    .timeline .time-bar-list {
        background: none;
    }

    .timeline .time-bar-list:before {
        display: none;
    }

    .timeline .year {
        position: static;
        font-size: 38px;
        line-height: 46px;
    }

    .timeline .year-content {
        width: 100%;
        float: none;
    }

    .sidebar-categories-list {
        padding-left: 20px;
        padding-right: 20px;
    }

    .posts .col-4 {
        width: 100%;
    }

    .archive .posts {
        padding: 0 3%;
    }

    .single-post .entry-header .entry-meta  {
        font-size: 14px;
        color: #1E1E1E;
        letter-spacing: 0.5px;
        line-height: 24px;
    }

    .royal-slider {
        min-height: 300px !important;
    }
    /*.single-post .entry-meta .entry-date,*/
    /*.single-post .entry-meta .cat-links {*/
        /*display: block;*/
    /*}*/

}