html, body {
    @extend %font-helvetica-neue-light;
    color: $font-default-color;
    font-size: $font-base-size;
    line-height: $font-base-line-height;
}

h1, h2, h3, h4, h5, h6,
.heading-1, .heading-2, .heading-3 {
    @extend %font-sabon-roman;
    color: $font-default-color;
    line-height: 1.0;
    letter-spacing: 0;

    a {
        text-decoration: none;
        color: $font-default-color;
    }
}

h1,
.heading-1 {
    font-size: 48px;
}

h2,
.heading-2 {
    font-size: 36px;
    margin-bottom: $margin-small - 5;
}

h3,
.heading-3 {
    font-size: 32px;
}

h4 {
    font-size: 30px;
}

h5 {
    font-size: 28px;
}

h6 {
    font-size: 22px;
}

.subline-1,
.subline-2,
.subline-3,
.subline-4 {
    @extend %font-helvetica-neue-light;
    color: $font-default-color;
}

.subline-1 {
    font-size: 26px;
    line-height: 32px;
}

.subline-2 {
    font-size: 20px;
    line-height: 26px;
}

.subline-3 {
    font-size: 18px;
    line-height: 24px;
}

.subline-4 {
    font-size: 16px;
    line-height: 22px;
}

.caption {
    @extend %font-helvetica-neue-light;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.8px;
    color: $font-default-color;
}

a {
    @extend %font-helvetica-neue-cond;
    color: $font-default-color;
    text-decoration: underline;

    &:hover {
        color: $font-default-highlight-color;
    }

    &:active {
        color: $font-default-active-color;
    }

    &.small {
        font-size: 15px;
    }
}

p {
    @extend %font-helvetica-neue-light;
    // margin-top: $margin-small; HAHA
    margin-top: 20px;

    &.small {
        font-size: 15px;
        line-height: 24px;
    }
}

strong {
    @extend %font-helvetica-neue-medium;
}

time,
.time-like {
    @extend %font-sabon-roman;
    // color: $color-red;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0;

    &.medium {
        font-size: 21px;
        line-height: 26px;
    }

    &.small {
        font-size: 18px;
        line-height: 23px;
    }
}

.section-headline {
    @extend %font-sabon-roman;
    font-size: 19px;
    letter-spacing: 7px;
    line-height: 40px;
    text-transform: uppercase;

    &.small {
        font-size: 16px;
        line-height: 30px;
    }
}

hr {
    border: none;
    border-bottom: 1px solid $color-medium-grey;
    margin-bottom: $margin-medium;

    &.signet {
        height: 30px;
        border-bottom: none;
        background: url('./img/bg_signet.png') no-repeat center;

        @media only screen and (max-width: 640px) {
            height: 24px;
            background: url('./img/bg_signet_small.png') no-repeat center;
        }
    }
}

.slogan {
    @extend %font-sabon-italic;
    font-size: 34px;
    line-height: 41px;

    &.small {
        opacity: 0.89;
        font-size: 24px;
        line-height: 28px;
    }
}

.button {
    @extend %font-helvetica-neue-light;
    width: 207px;
    height: 50px;
    line-height: 50px;
    text-decoration: none;
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: 1.35px;
    text-align: center;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
}

.button-primary {
    @extend .button;
    background-color: $color-primary;

    &:hover {
        background-color: $font-default-highlight-color;
    }

    &:active {
        background-color: $font-default-active-color;
    }
}