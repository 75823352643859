.c-cookie {
    align-items: center;
    background-color: $color-light-grey;
    display: flex;
    justify-content: center;
    padding: $margin-mini $margin-small;

    @media (max-width: 640px) {
        flex-wrap: wrap;
    }

    &__holder {
        display: none;
        width: 100%;

        @media (max-width: 960px) {
            bottom: 0;
            left: 0;
            position: fixed;
            z-index: 200;
        }
    }

    &__buttonholder {
        align-items: center;
        display: flex;
        padding-left: $margin-mini;
    }

    &__dismiss {
        background-color: #000;
        border: none;
        color: #FFF;
        letter-spacing: 1px;
        line-height: 46px;
        margin-right: 2rem;
        outline: none;
        padding: 0 15px;
        text-decoration: none !important;
        text-transform: uppercase;

        @media (max-width: 640px) {
            margin-top: 16px;
        }
    }

    &__text {
        margin-top: 0;

        @media (max-width: 960px) {
            font-size: 12px;
        }
    }
}
