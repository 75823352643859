
#PageHeader {
    height: 140px;
    padding: 32px 0 24px;

    .logo {
        max-width: none;
    }

    .col {
        margin-bottom: 0;
    }
}
