%font-sabon-roman {
    font-family: 'Sabon LT W01 Roman', serif;
//    font-weight: 400;
//    font-style: normal;
}

%font-sabon-italic {
    font-family: 'Sabon LT W01 Italic', serif;
//    font-weight: 400;
//    font-style: italic;
}

%font-helvetica-neue {
    font-family: 'HelveticaNeueW01-47LtCn_692686', sans-serif;
//    font-weight: 400;
//    font-style: normal;
}

%font-helvetica-neue-light {
    font-family:'HelveticaNeueW01-45Ligh', sans-serif;;
//    font-family: $font-family-sans-serif;
//    font-weight: 300;
//    font-style: normal;
}

%font-helvetica-neue-italic {
    font-family:'HelveticaNeueW01-46Ligh', sans-serif;;
//    font-family: $font-family-sans-serif;
//    font-weight: 400;
//    font-style: italic;
}

%font-helvetica-neue-medium {
    font-family:'HelveticaNeueW01-65Medi', sans-serif;;
//    font-family: $font-family-sans-serif;
//    font-weight: 500;
//    font-style: normal;
}

%font-helvetica-neue-cond {
    font-family: 'HelveticaNeueW01-ThinCn_673383', sans-serif;;
//    font-weight: 300;
//    font-style: normal;
}

%font-helvetica-neue-light-cond {
    font-family: 'HelveticaNeueW01-47LtCn_692686', sans-serif;;
//    font-weight: 300;
//    font-style: normal;
}