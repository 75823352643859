.c-slider {
    max-height: none;

    &__slide {
        background-size: cover;
        background-position: top center;
        max-height: 632px;
        width: 100%;
    }

    .mainstage-label {
        bottom: 0;
        left: 0;
        @include media("<=large") {
            left: 18%;
        }
        @include media("<=xsmall") {
            background-color: transparent;
            left: 0;
        }
    }

    .container {
        margin: 0;
        padding-left: 50px !important;
        max-width: 1440px;
        @include media("<=xsmall") {
            padding-left: 0 !important;
        }
    }
}

// slick slider overwrite
button.slick-arrow {
    width: 60px;
    height: 60px;
    background-size: 60px;
    background-position: center center;
    background-color: rgba(0,0,0,0.5);
    margin-top: -60px;
    transition: 0.5s;
}
button.slick-arrow:hover {
    background-color: rgba(0,0,0,0.7);
}
button.slick-next {
    background-image: url("../../../img/ico_rs_arrow_right_nobg.png");
}
button.slick-prev {
    background-image: url("../../../img/ico_rs_arrow_left_nobg.png");
}
.slick-dots button:before {
    background-color: #000;
    width: 15px;
    height: 15px;
}
.slick-slide img {
    width: 100%;
}
@media (max-height: 740px) and (min-width: 800px) {
    .stage-start .outer-container {
        bottom: 150px;
    }
    .slidercon .container {
        padding-left: 80px !important;
    }
}
@media (max-height: 600px) {
    .stage-start .outer-container {
        bottom: 300px;
    }
}

.slick-list.draggable {
    background-color: $color-light-grey;
}