/* Global :: Elements  */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font,
img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i,
center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td { margin: 0; padding: 0; border: 0; outline: 0; font-size: 100%; vertical-align: baseline; background: transparent; }
html { font-family: sans-serif; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; -webkit-font-smoothing: antialiased; height: 100%; overflow-y: scroll; }
body { line-height: 1; }
ol, ul { list-style: none; }
/*blockquote, q { quotes: none; }*/
/*blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }*/
:focus { outline: 0; }
ins { text-decoration: none; }
del { text-decoration: line-through; }
table { border-collapse: collapse; border-spacing: 0; }

/* HTML5 :: Elements */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary { display: block; }

/* Clear floating elements */
.screen-clear,
.tablet-clear,
.mobile-clear { display: none; visibility: hidden; clear: both; height: 0; float: none; }
.clearfix { display: block; }
.clearfix:after { clear: both; content: "."; display: block; height: 0; visibility: hidden; line-height: 1px; }

/* Special for mobile devices */
input[type="checkbox"],
input[type="radio"] { box-sizing: border-box; padding: 0; }
input[type="search"] { -webkit-appearance: textfield; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box; }
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

@charset "UTF-8";
/*
Theme Name: Fürstenberg Tourismus
Theme URI: http://www.dotfly.de
Author: Dustin Breuer <dustin.breuer@dotfly.de>
Author URI: http://www.dotfly.de
Template: fuerstenberg-tourismus
Description: Das Fürstenberg-Tourismus Template
Version: 1
License: Commercial
License URI: http://www.dotfly.de
Tags:
Text Domain: fbt
 */
/* line 5, ../../../../../../../../../lib/gems/1.9.1/gems/compass-core-1.1.0.alpha.3/stylesheets/compass/reset/_utilities.scss */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

html {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

caption, th, td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle;
}

q, blockquote {
    quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
    content: "";
    content: none;
}

a img {
    border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}