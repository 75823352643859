
/**
 * 0.1 Colors
 * -----------------------------------------------------------------------------
 */

$color-black:       #000000;
$color-mint:        #0BB085;
$color-turquoise:   #07B5AD;
$color-bright-blue: #44C8F5;
$color-blue:        #1B3F93;
$color-violet:      #9D3293;
$color-rubin-red:   #FF2C78;
$color-cool-grey:   #ADAFB2;
$color-medium-grey: #DDDDDD;
$color-red:         #E31837;
$color-warm-red:    #F26531;
$color-yellow:      #FFE510;
$color-light-grey:  #F2F2F2;
$color-dark-grey:   #ADAFB2;
$color-grey:        #ADAFB2;

$color-primary: $color-black;

$font-family-serif: 'Sabon', serif;
$font-family-sans-serif: 'Helvetica Neue', 'HelveticaNeue', 'Helvetica', sans-serif;

$font-base-size: 18px;
$font-base-weight: 300;
$font-base-line-height: 28px;
$font-base-letter-spacing: 0.8px;

$font-default-color: $color-black;
$font-default-highlight-color: $color-cool-grey;
$font-default-active-color: $color-medium-grey;

$margin-mini: 18px;
$margin-small: 35px;
$margin-medium: 50px;
$margin-large: 70px;

$max-width-tablet: 960px;
$max-width-phone: 640px;

// ==========================================================================
// Variables - MediaQueries :: Grid
// ==========================================================================

// Usage:
// @include media(">small") {
//     ...stuff
// }

$breakpoints: (
        'handheld': 21.5rem, // 340px
        'mobile': 33.5rem, //536px
        'xsmall': 40rem, // 640px
        'small': 48rem, // 768px
        'desktop': 60rem, // 960px
        'medium': 64rem, // 1024px
        'wide': 80rem, // 1280px
        'large': 90rem, // 1440px
        'screen': 95rem, // 1520px
        'xlarge': 100rem, // 1600px
        'ultra': 123.8rem, // 1980px
        'ultrahd': 162.5rem // 2560px
);